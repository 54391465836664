import React from "react";
import { Switch} from "react-router-dom";
import loadRouteFromMenuLinkDescription from "../utils/routes/loadRouteFromMenuLinkDescription";
import guestLinks from "../components/common/Navigation/GuestLinks";
import UniversalRoutes from "./UniversalRoutes";
import {useUID} from "react-uid";

interface PropsI {
    routeInformationen?: Array<RouteInformation>
}

const GuestRoutes = (props: PropsI) => {

    return (
        <Switch>
            {loadRouteFromMenuLinkDescription(guestLinks)}
            <UniversalRoutes />
        </Switch>
    )
}

export default GuestRoutes;