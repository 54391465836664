import React from "react";
import {useTranslation} from "react-i18next";
import translateListOfObjects from "../../../utils/translation/translateListOfObjects";
import Login, {standardColumns} from "../../../models/Login";
import APIDatatable from "../../common/APIDatatable/APIDatatable";

const LoginList: React.FC<{}> = () => {

    const { t } = useTranslation("table_header")
    translateListOfObjects(standardColumns, "selector", "name", t,"logins.")

    return (
        <APIDatatable
            endpoint={"/logins"}
            columns={standardColumns}
            modelConstructor={(init) => new Login(init)}
            onRowClicked={() => {}}
        />
    )
}

export default LoginList