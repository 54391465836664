import axios from "../axios/apiv1";

async function performPutRequest(endpoint: string | null, instance: object): Promise<boolean> {

    // It's possible to not give an endpoint. This should not leave total failure to make it unnecessary to catch a in every application
    if(endpoint) {
        return await axios.put(endpoint, instance).then(() => {
            return true
        }).catch((error) => {
            console.log(error)
            return false
        })
    }

    console.log("No endpoint was given for the put request.")
    return false
}

export default performPutRequest