import React from "react"
import APIDatatable from "../../common/APIDatatable/APIDatatable";
import Conflict, {standardColumns} from "../../../models/Conflict";
import {useTranslation} from "react-i18next";
import translateListOfObjects from "../../../utils/translation/translateListOfObjects";

interface PropsI {
    onRowClicked: (row: any ) => void
}

const ConflictList: React.FC<PropsI> = (props: PropsI) => {

    const { t } = useTranslation("table_header")
    translateListOfObjects(standardColumns, "selector", "name", t,"conflicts.")

    return (
        <APIDatatable
            columns={standardColumns}
            endpoint={"/conflicts"}
            modelConstructor={(init) => new Conflict(init)}
            searchString
            {...props}
        />
    )
}

export default ConflictList