class PaymentMethod {
    public type: string = ""
    public needs_approval: boolean = true
    public default_fixed_fee?: number
    public default_variable_fee?: number
    public created_at: string = ""
    public updated_at: string = ""

    constructor(init: Partial<PaymentMethod>) {
        Object.assign(this, init)
    }
}


export default PaymentMethod