import React, {ReactNode, useContext} from "react";
import ContentEmbedding from "../common/Content/ContentEmbedding";
import Section, {SectionContent, SectionHeader} from "../common/Content/Section";
import ShopList from "../resources/List/ShopList";
import CreateShopForm from "../resources/Form/CreateShopForm";
import PermissionContext from "../../contexts/PermissionContext";
import { useTranslation } from "react-i18next";

const Shops: React.FC<{}> = () => {

    const {t} = useTranslation()

    const permissionMap = useContext(PermissionContext)

    return <ContentEmbedding title={t("navigation:shop")}>

        {renderShopList()}

        <br />

        <Section>
            <SectionContent>
                <CreateShopForm />
            </SectionContent>
        </Section>
    </ContentEmbedding>


    // Only render a shop overview if the user is associated with at least one shop.
    function renderShopList(): ReactNode {
        if(permissionMap && permissionMap.size > 0)
            return <Section>
                <SectionHeader>Shop</SectionHeader>
                <SectionContent>
                    <ShopList />
                </SectionContent>
            </Section>

        return null
    }
}

export default Shops