import React from "react";
import APIDatatable from "../../common/APIDatatable/APIDatatable";
import SubResourceInterface from "./SubResourceInterface";
import PaymentMethodCredential from "../../../models/PaymentMethodCredential";

const PaymentMethodCredentialList: React.FC<SubResourceInterface> = (props) => {
    return (
        <APIDatatable
            endpoint={"/shops/" + props.shopId.toString() + "/payment-method-credentials"}
            columns={props.standardColumns}
            rowsPerPage={30}
            pagination={false}
            onClickTarget={"/payment-method-credentials"}
            modelConstructor={(init) => new PaymentMethodCredential(init)}
        />
    )
}

export default PaymentMethodCredentialList