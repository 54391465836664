import React from "react"
import {LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, LabelList} from "recharts"
import useGetAPI from "../../http/hooks/useGetAPI";
import APIBuffering from "../common/APIBuffering";
import colors from "./colors";


const PaymentTransactionLineChart: React.FC<{}> = (props: {}) => {

    const getAPI = useGetAPI<object[]>("/stats/payment-transactions")
    const data = getAPI.responseBody ? getAPI.responseBody : [];
    let maximum_amount_sum = 0
    data.forEach((element: any) =>
        maximum_amount_sum = Math.max(maximum_amount_sum, element.amount_sum)
    )


    return (
        <APIBuffering {...getAPI}>
            <LineChart width={1200} height={300} data={data}>
                <CartesianGrid strokeDasharray={"3 3"} />
                <XAxis dataKey={"week"} />
                {/* Leaving whitespace above the graph. But leaving an decimal number looks ugly so rounding it. */}
                <YAxis domain={[0,Math.round(maximum_amount_sum*1.1)]}/>
                <Tooltip />
                <Line type={"monotone"} dataKey={"amount_sum"} />
            </LineChart>
        </APIBuffering>
    )
}

export default PaymentTransactionLineChart