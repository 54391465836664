import React, {useState} from "react"
import { useTranslation } from "react-i18next";
import performPutRequest from "../../../http/functions/performPutRequest";
import { useHistory } from "react-router-dom";

interface PropsI {
    payoutId: number
}

const ProcessPayoutForm: React.FC<PropsI> = (props: PropsI) => {

    const [note, setNote] = useState<string>("")

    const {t} = useTranslation()

    const history = useHistory()

    return (
        <React.Fragment>
            <div style={{textAlign: "center"}}>
                <input type={"text"} className={"form-control"} onChange={(event) => setNote(event.currentTarget.value)} value={note} placeholder={"Enter a note here."} />

                <br />
                <br />
                <input type={"button"} className={"from-control btn btn-outline-success"} value={t("general:form_actions.process").toString()} onClick={ async () => {
                    const success = await performPutRequest("/payouts/" + props.payoutId.toString(), {
                        note,
                        paid_out_at: "now",
                    })

                    if(success) {
                        const currentLocation = history.location
                        history.push("/reload")
                        history.replace(currentLocation)
                    }

                }} />
            </div>

        </React.Fragment>
    )
}

export default ProcessPayoutForm