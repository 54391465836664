import React from "react"
import ContentEmbedding from "../common/Content/ContentEmbedding";
import UserList from "../resources/List/UserList";

const Users: React.FC<{}> = () => {

    return(
        <React.Fragment>
            <ContentEmbedding>
                <UserList />
            </ContentEmbedding>
        </React.Fragment>
    )
}

export default Users