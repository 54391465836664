import React, {useState} from "react"
import {useHistory} from "react-router-dom"
import useURLQuery from "../../../utils/routes/useURLQuery";

const Search: React.FC<{}> = () => {

    const history = useHistory()
    const query = useURLQuery().get("q")

    const [globalSearch, setGlobalSearch] = useState<string>(query ? query : "")

    return (
        <div className={"input-group hidden-xs-down wd-500 transition"}>
            <input type={"text"} className={"form-control"} placeholder={"Search"} value={globalSearch} onChange={(event) => setGlobalSearch(event.currentTarget.value)}/>
            <span className={"input-group-btn"} >
                <button type={"button"} className={"btn btn-secondary"} onClick={() => {
                    history.push("/global-search?q="+globalSearch)
                }}>
                <i className={"fa fa-search"} />
                </button>
            </span>
        </div>
    )
}

export default Search