import React from "react"
import useGetAPI from "../../../http/hooks/useGetAPI";
import {useParams, useHistory} from "react-router-dom";
import User, {singleResourceDisplay} from "../../../models/User";
import APIBuffering from "../../common/APIBuffering";
import Section, {SectionContent, SectionHeader} from "../../common/Content/Section";
import DisplayInformationList from "../../common/Content/DisplayInformationList";
import ContentEmbedding from "../../common/Content/ContentEmbedding";
import DisplayExistingVerificationFiles from "../subresources/DisplayExistingVerificationFiles";
import performPutRequest from "../../../http/functions/performPutRequest";


const ApproveShop: React.FC<{}> = () => {

    const history = useHistory()

    const { id } = useParams()
    const getAPI = useGetAPI<User>("/users/" + id)

    let processedSingleResourceDisplay =  [...singleResourceDisplay]


    return (
        <APIBuffering {...getAPI}>
            <ContentEmbedding>
                <Section>
                    <SectionHeader>Basic Information</SectionHeader>
                    <SectionContent>
                        <DisplayInformationList translationPath={"user"} structure={processedSingleResourceDisplay} instance={getAPI.responseBody ? getAPI.responseBody : {}} />
                        <input className={"btn btn-success"} type={"Button"} value={"Approve"} onClick={async () => {
                            const success = await performPutRequest("/users/" + getAPI.responseBody!.id!.toString(), {
                                state: "ACTIVE"
                            })

                            if(success)
                                history.push("/review")

                        }} />
                    </SectionContent>
                </Section>

                <br />

                <Section>
                    <SectionHeader>Provided Files</SectionHeader>
                    <SectionContent>
                        <DisplayExistingVerificationFiles shopId={getAPI.responseBody ? getAPI.responseBody.id : 0}>

                        </DisplayExistingVerificationFiles>
                    </SectionContent>
                </Section>
            </ContentEmbedding>
        </APIBuffering>
    )
}


export default ApproveShop