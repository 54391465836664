/*
 * This function will convert from a datetime format like 2020-06-12T13:05:05.000000Z to to a human readable format (12.06.2020 15:05 for German locale) in one go. Just a wrapper around the Date object.
 *
 * @param iso8601_date The date in the iso format as is returned from a standard REST API
 * @param locale The time format can overwritten manually to not use the browser specified one.
 * @param options The same as the Date.prototype.toLocalDateString's options
 * @return The formatted datetime as a string.
 */
export default function dateFromIso8601ToLocal(iso8601_date: string | null | undefined, locale: string | string[] = [], options: object = {hour: '2-digit', minute: '2-digit'}): string | null {
    if(!iso8601_date)
        return null

    const date = new Date(Date.parse(iso8601_date));
    return (date.toLocaleDateString(locale, {...options}));
}
