// Adapted from https://stackoverflow.com/a/286162

import cloneObject from "./cloneObject";

function removeKeysWithNullValue<T extends object, K extends keyof T>(obj: T) {

    const clone = cloneObject(obj)

    for (let propName in clone) {
        if (clone[propName] === null || clone[propName] === undefined) {
            delete clone[propName];
        }
    }

    return clone
}

export default removeKeysWithNullValue;