import React, {useState} from "react"
import Section, {SectionContent} from "../common/Content/Section";
import ContentEmbedding from "../common/Content/ContentEmbedding";
import ConflictList from "../resources/List/ConflictList";
import Conflict from "../../models/Conflict";
import SolveConflictForm from "../resources/Form/SolveConflictForm";

const Conflicts: React.FC<{}> = () => {

    const [selectedConflict, setSelectedConflict] = useState<Conflict| null>(null)

    return (
        <ContentEmbedding title={Conflicts}>
            <Section>
                <SectionContent>

                    <ConflictList
                        onRowClicked={(row: any) => setSelectedConflict(new Conflict(row))}
                    />

                    <br />
                    <br />

                    {selectedConflict && selectedConflict.id ? <SolveConflictForm conflictId={selectedConflict.id} /> : null}

                </SectionContent>
            </Section>
        </ContentEmbedding>
    )
}

export default Conflicts