import React from "react";
import {logout} from "../../utils/auth/authController"

const AuthLogout = () => {
    logout()
    return <div>Logging out..</div>;
}



export default AuthLogout;