import React from "react";
import {Route, Switch} from "react-router-dom";
import loadRouteFromMenuLinkDescription from "../utils/routes/loadRouteFromMenuLinkDescription";
import UniversalRoutes from "./UniversalRoutes";
import inactiveUserLinks from "../components/common/Navigation/InactiveUserLinks";
import AddUserVerification from "../components/pages/AddUserVerification";
import Security from "../components/pages/Security";
import AccountInformation from "../components/pages/Users/AccountInformation";
import AuthLogout from "../components/auth/AuthLogout";

interface PropsI {
    routeInformationen?: Array<RouteInformation>
}

const InactiveUserRoutes = (props: PropsI) => {

    return (
        <Switch>
            {loadRouteFromMenuLinkDescription(inactiveUserLinks)}
            <Route exact path={"/"} component={AddUserVerification} />
            <Route exact path={"/users/security"} component={Security} />
            <Route exact path={"/users/account-information"} component={AccountInformation} />
            <Route exact path={"/logout"} component={AuthLogout} />
            <UniversalRoutes />
        </Switch>
    )
}

export default InactiveUserRoutes;