const colors: string[] = [
    "#46526b",
    "#615a84",
    "#8a5e92",
    "#b85e92",
    "#e15f83",
    "#fd6b67",
    "#ff8442",
    "#ffa600",
    "#ffbe00",
]

export default colors