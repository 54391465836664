import {IDataTableColumn} from "react-data-table-component";
import React from "react";
import {TFunction} from "i18next";
import dateFromIso8601ToLocal from "../utils/time/dateFromIso8601ToLocal";

class PaymentTransaction {

    uuid?: string
    description?: string
    amount?: number
    payment_method_type?: string
    reference?: string
    external_transaction_id?: string
    state?: string
    fixed_fee?: number
    variable_fee?: number
    calculated_variable_fee?: number
    email?: string
    country_code?: string
    updated_at?: string



    constructor(init: Partial<PaymentTransaction>) {
        Object.assign(this, init)
    }

    genericDisplay() {
        return (
            <>
                <h6 className={"tx-white"}>Ticket Message</h6>
                {this.uuid} <br />
                {this.description}
            </>
        )
    }

    get moreInformationPage(): string {
        return "/payment-transactions/" + this.uuid
    }

    apiDatatableDisplay(t: TFunction): any {
        return {
            uuid: this.uuid,
            description: this.description,
            amount: this.amount,
            payment_method_type: this.payment_method_type,
            reference: this.reference,
            external_transaction_id: this.external_transaction_id,
            state: this.state,
            fixed_fee: this.fixed_fee,
            variable_fee: this.variable_fee,
            calculated_variable_fee: this.calculated_variable_fee,
            email: this.email,
            country_code: this.country_code,
            updated_at: dateFromIso8601ToLocal(this.updated_at)
        }
    }

}

export const standardColumns: Array<IDataTableColumn<any>> = [
    {
        name: "",
        selector: "uuid",
        grow: 2,
        wrap: true,
    }, {
        name: "",
        selector: "description",
    }, {
        name: "",
        selector: "amount",
        sortable: true,
    }, {
        name: "",
        selector: "payment_method_type",
        sortable: true,
    }, {
        name: "",
        selector: "reference",
        sortable: true,
    }, {
        name: "",
        selector: "external_transaction_id",
        sortable: true,
    }, {
        name: "",
        selector: "fixed_fee",
        sortable: true,
    }, {
        name: "",
        selector: "variable_fee_calculated",
        sortable: true,
    }, {
        name: "",
        selector: "email",
        sortable: true,
    }, {
        name: "",
        selector: "country_code",
        sortable: true,
    }, {
        name: "",
        selector: "updated_at",
        sortable: true,

    }
]

export default PaymentTransaction