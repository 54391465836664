import {IDataTableColumn} from "react-data-table-component";
import React from "react";
import {TFunction} from "i18next";
import dateFromIso8601ToLocal from "../utils/time/dateFromIso8601ToLocal";

class Ticket {
    public id?: number
    public shop_id?: number
    public user_id?: number
    public subject?: string
    public solved?: boolean
    public created_at?: string
    public updated_at?: string

    constructor(init: Partial<Ticket>) {
        Object.assign(this, init)
    }

    genericDisplay() {
        return (
            <>
                <h6 className={"tx-white"}>Ticket Message</h6>
                {this.subject}
            </>
        )
    }

    get moreInformationPage(): string {
        return "/tickets/" + this.id
    }

    apiDatatableDisplay(t: TFunction): any {

        const translateYesNo = (value: boolean | undefined): string | null => {
            return value !== undefined ? t("boolean:YesNo." + Boolean(value).toString()) : null
        }

        return {
            id: this.id,
            subject: this.subject,
            solved: translateYesNo(this.solved),
            created_at: dateFromIso8601ToLocal(this.created_at),
            updated_at: dateFromIso8601ToLocal(this.updated_at),
        }
    }

}

export const standardColumns: Array<IDataTableColumn<any>> = [
    {
        name: "",
        selector: "id",
        sortable: true,
    },{
        name: "",
        selector: "subject",
        sortable: false,
    },{
        name: "",
        selector: "solved",
        sortable: true,
    }, {
        name: "",
        selector: "created_at",
        sortable: true
    }, {
        name: "",
        selector: "updated_at",
        sortable: true
    }
]


export const singleResourceDisplay: Array<SingleResourceDisplayStructure> = [
    {
        identifier: "subject",
    },{
        identifier: "solved",
        valueTransform: "BooleanYesNo",
    },{
        identifier: "created_at",
        valueTransform: "DateAndTime",
    },{
        identifier: "updated_at",
        valueTransform: "DateAndTime",
    }
]

export default Ticket