import React, {useState} from "react"
import PaymentMethodCredential from "../../../models/PaymentMethodCredential";
import useGetAPI from "../../../http/hooks/useGetAPI";
import APIBuffering from "../../common/APIBuffering";
import performPutRequest from "../../../http/functions/performPutRequest";

interface PropsI {
    paymentMethodCredentialId?: number | null
    onSuccess?(): void
}

const ApprovePaymentMethodCredentialForm: React.FC<PropsI> = (props: PropsI) => {

    const getAPI = useGetAPI("/payment-method-credentials/" + props.paymentMethodCredentialId)
    const credential: any = getAPI.responseBody ? getAPI.responseBody : null

    const [fixedFee, setFixedFee] = useState<string>("");
    const [variableFee, setVariableFee] = useState<string>("");

    // can have up to 3 numbers infront of the decimal and if the decimal is set then it needs at least 1 more number and can have up to 5. Also possible: Only decimal and 1 to 5 numbers
    // Examples work: 231.1234   2.23   2.3   0.2312
    const regexp = RegExp("(^[0-9]{1,3}(.[0-9]{1,5})?$|^.[0-9]{1,5}$|)")

    if(credential)
        return(
            <form>
                <input type={"text"} className={"form-control"} placeholder={"Fixed Fee: " + credential.fixed_fee} value={fixedFee} onChange={(event)  => {
                    event.persist()
                    setFixedFee(event.currentTarget.value)
                } }/>

                <br />

                <input type={"text"} className={"form-control"} placeholder={"Variable Fee: " + credential ? credential.variable_fee : ""} value={variableFee} onChange={(event)  => {
                    event.persist()
                    setVariableFee(event.currentTarget.value)
                }} />

                <br/>
                <br/>

                <input type={"button"} disabled={!regexp.test(fixedFee) || ! regexp.test(variableFee) } className={"btn btn-outline-success form-control"} value={"Approve"} onClick={async () => {
                    if(props.paymentMethodCredentialId) {
                        const request: any = { is_activated: true}

                        if(fixedFee !== "")
                            request["fixed_fee"] = fixedFee

                        if(variableFee !== "")
                            request["variable_fee"] = variableFee

                        const success = await performPutRequest("/payment-method-credentials/" + props.paymentMethodCredentialId.toString(), request)
                        if(success) {
                            if(props.onSuccess)
                                props.onSuccess()

                            setFixedFee("")
                            setVariableFee("")
                        }

                    }
                }}/>
            </form>
        )

    return <React.Fragment />

}

export default ApprovePaymentMethodCredentialForm