import React from "react"

import User, {standardColumns} from "../../../models/User"
import APIDatatable from "../../common/APIDatatable/APIDatatable";
import translateListOfObjects from "../../../utils/translation/translateListOfObjects";
import {useTranslation} from "react-i18next"
import { useHistory } from "react-router-dom";

const UserList = () =>  {

    const history = useHistory()

    const { t } = useTranslation("table_header")
    translateListOfObjects(standardColumns, "selector", "name", t,"users.")

    return <APIDatatable
        columns={standardColumns}
        endpoint={"/users"}
        onRowClicked={(row) => {
            localStorage.setItem("impersonating", row.id)
            history.push("/")
            window.location.reload()
        }}
        modelConstructor={(init) => new User(init)}
        searchString
    />
}

export default UserList;