import React from "react"

import Shop, {standardColumns} from "../../../models/Shop"
import APIDatatable from "../../common/APIDatatable/APIDatatable";
import translateListOfObjects from "../../../utils/translation/translateListOfObjects";
import {useTranslation} from "react-i18next"

const ShopList = () =>  {

    const { t } = useTranslation("table_header")
    translateListOfObjects(standardColumns, "selector", "name", t,"shops.")

    return <APIDatatable
        columns={standardColumns}
        endpoint={"/shops"}
        modelConstructor={(init) => new Shop(init)}
        searchString
    />
}

export default ShopList;