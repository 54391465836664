import {IDataTableColumn} from "react-data-table-component";
import Ticket from "./Ticket";
import React, {ReactNode} from "react";
import User from "./User";
import dateFromIso8601ToLocal from "../utils/time/dateFromIso8601ToLocal";

class TicketMessage {
    public id?: number
    public ticket_id?: number
    public sender?: User
    public message?: string
    public attachment?: string
    public attachment_name?: string
    public created_at?: string
    public updated_at?: string

    constructor(init: Partial<TicketMessage>) {
        Object.assign(this, init)
        if(init.sender)
            this.sender = new User(init.sender);
    }

    genericDisplay(): string | ReactNode {
        return (
            <>
                <h6 className={"tx-white"}>Ticket Message</h6>
                {this.message}
            </>
        )
    }

    messageOverviewDisplay(): any {
        return {
            id: this.id,
            sender: this.sender,
            message: this.message,
            attachment: this.attachment,
            attachment_name: this.attachment_name,
            created_at: dateFromIso8601ToLocal(this.created_at),
        }
    }

    get moreInformationPage(): string {
        return "/tickets/" + this.ticket_id
    }
}

export const standardColumns: Array<IDataTableColumn<any>> = [
    {
        name: "",
        selector: "id",
        sortable: false,
    },{
        name: "",
        selector: "ticket_id",
        sortable: false,
    },{
        name: "",
        selector: "sender.fullname",
        sortable: false,
    }, {
        name: "",
        selector: "message",
        sortable: false
    }, {
        name: "",
        selector: "created_at",
        sortable: false
    }, {
        name: "",
        selector: "updated_at",
        sortable: false
    }
]

export default TicketMessage