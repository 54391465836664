import React, {ReactElement} from "react"
import {Link} from "react-router-dom";




import { useTranslation } from "react-i18next"

import adminLinks from "./AdminLinks";
import userLinks from "./UserLinks";
import guestLinks from "./GuestLinks";
import {useUID} from "react-uid";
import LanguageSelector from "../HeadBar/LanguageSelector";
import User from "../../../models/User";
import {TFunction} from "i18next";
import inactiveUserLinks from "./InactiveUserLinks";

interface PropsI {
    loggedIn: boolean
    user: User | null
}

const Navigation = (props: PropsI) => {

    //const view = (props.loggedIn) ? (props.user && props.user.is_admin) ? adminLinks : userLinks : guestLinks

    let view = guestLinks

    if(props.loggedIn)
        if(props.user) {
            if (props.user.is_admin)
                view = adminLinks
            else if(props.user.state !== "ACTIVE")
                view = inactiveUserLinks
            else
                view = userLinks
        }


    const {t} = useTranslation()

    return(
        <React.Fragment>
                <div className="br-logo"><a href="/">Alma2</a></div>
                <div className="br-sideleft sideleft-scrollbar">
                    <label className="sidebar-label">Navigation</label>
                    <ul className="br-sideleft-menu">
                        { renderLinks(view, t ) }
                    </ul>

                    <div className={"sidebar-label pd-x-10 mg-t-25 mg-b-20 tx-info"} style={{paddingTop: "100px"}}>
                        Language
                    </div>

                    <div className={"info-list"}>
                        <LanguageSelector />
                    </div>

                </div>

        </React.Fragment>
    )
}


function renderLinks(links: Array<MenuLinkDescription>, t: TFunction): ReactElement[] {
    const processedLinks: Array<ReactElement> = []

    links.forEach((singleLink: MenuLinkDescription) => {
        // Empty string if it does not have a sub menu. Respective class addition if it does.
        const withSub = (singleLink.subMenus && singleLink.subMenus.length > 0) ? " with-sub" : ""
        processedLinks.push(
            <li key={singleLink.identifier} className="br-menu-item">
                <Link to={singleLink.path} className={"br-menu-link" + withSub}>
                    <i className={"menu-item-icon icon tx-24 " + singleLink.icon}></i>
                    <span className="menu-item-label">{ t("navigation:"+singleLink.identifier) }</span>
                </Link>
                {renderSubLinks(singleLink.subMenus)}
            </li>)
    })

    return processedLinks
}

function renderSubLinks(subMenues: Array<SubMenuLinkDescription> | undefined): ReactElement {
    if(!subMenues || subMenues.length === 0)
        return <React.Fragment></React.Fragment>

    const sublinks: Array<ReactElement> = []
    subMenues.forEach((sub: SubMenuLinkDescription) => {
        sublinks.push(
            <li  key={sub.identifier} className="sub-item">
                <Link to={sub.path} className="sub-link">
                    {useTranslation().t("navigation:sub-link." + sub.identifier)}
                </Link>
            </li>
        )
    })


    return <ul className="br-menu-sub">
        {sublinks}
    </ul>
}

export default Navigation