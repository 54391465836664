import React, {FormEvent, ReactElement} from "react";
import { useTranslation } from "react-i18next";
import useGetAPI from "../../../http/hooks/useGetAPI";

interface PropsI {
    endpoint: string
    parameters?: object
    valueKey: string
    displayKey: string
    className?: string
    onChange(event: FormEvent<HTMLSelectElement>): void
}

const APISelect:React.FC<PropsI> = (props: PropsI) => {

    const {t} = useTranslation();
    const {responseBody} = useGetAPI<any>(props.endpoint, props.parameters)


    return (
        <React.Fragment>
            <select className={props.className} onChange={(event: FormEvent<HTMLSelectElement>) => props.onChange(event)}>
                <option disabled selected hidden>{t("general:form_actions.select_one").toString()}</option>
                {renderOptions()}
            </select>
        </React.Fragment>
    )

    function renderOptions(): ReactElement[] {
        if(responseBody)
            return responseBody.map((element: any) => {
                return (
                    <option value={element[props.valueKey]}>{element[props.displayKey]}</option>
                )
            })

        return []
    }

}

export default APISelect