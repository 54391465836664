import NavigationIcons from "../../../types/NavigationIcons"
import AuthLogin from "../../auth/AuthLogin";
import AuthRegister from "../../auth/AuthRegister";

// This is no solved via navigation anymore, but still works to provide routes.
// It is keep incase guest pages will be built out to integrate a navigation again.
const guestLinks: Array<MenuLinkDescription> = [
    {identifier: "login", icon: NavigationIcons.Person, path:"/", component: AuthLogin},
    {identifier: "signup", icon: NavigationIcons.Wand, path: "/register", component: AuthRegister}
]



export default guestLinks