import React, {useState} from "react"
import performPutRequest from "../../../http/functions/performPutRequest";

const ChangePasswordForm: React.FC<{}> = () => {

    const [oldPassword, setOldPassword] = useState<string>("")
    const [newPassword, setNewPassword] = useState<string>("")
    const [newRetypedPassword, setNewRetypedPassword] = useState<string>("")

    return (
        <form>
            <input type={"password"} className={"form-control"} placeholder={"Old password"} value={oldPassword} onChange={(event) => setOldPassword(event.currentTarget.value)} />
            <br />
            <br />
            <input type={"password"} className={"form-control"} placeholder={"New Password"} value={newPassword} onChange={(event) => setNewPassword(event.currentTarget.value)} />
            <br />
            <input type={"password"} className={"form-control"} placeholder={"Retype new password"} value={newRetypedPassword} onChange={(event) => setNewRetypedPassword(event.currentTarget.value)} />

            <br />

            <div style={{textAlign: "center"}}>
                <input type={"button"} value="ChangePassword" className="btn btn-outline-success" onClick={async() => {

                    if(newPassword === newRetypedPassword) {
                        const success = await performPutRequest("/users/current/passwords", {
                            "new_password": newPassword,
                            "old_password": oldPassword,
                        })
                    }

                setOldPassword("")
                setNewPassword("")
                setNewRetypedPassword("")
                }} />
            </div>
        </form>
    )
}

export default ChangePasswordForm