import React, {useState} from "react"
import ContentEmbedding from "../common/Content/ContentEmbedding";
import Content from "../common/Content/Content";
import Section, {SectionContent, SectionHeader} from "../common/Content/Section";
import APIDatatable from "../common/APIDatatable/APIDatatable";
import Payout, {standardColumns} from "../../models/Payout";
import {useTranslation} from "react-i18next";
import translateListOfObjects from "../../utils/translation/translateListOfObjects";
import cloneObject from "../../utils/objects/cloneObject";
import ProcessPayoutForm from "../resources/Form/ProcessPayoutForm";

const Accounting: React.FC<{}> = () => {

    // If not cloned it will mutate the object and lead to doubling up of added columns or similar.
    let changedStandardColumns = cloneObject(standardColumns)

    // Remove paid_out_at column
    changedStandardColumns = changedStandardColumns.filter((element) => {
        return element.selector !== "paid_out_at"
    })

    // Add note column
    changedStandardColumns.push({
        name: "",
        selector: "note"
    })

    const { t } = useTranslation("table_header")
    translateListOfObjects(changedStandardColumns, "selector", "name", t,"table_header:payouts.")


    const [payoutId, setPayoutId] = useState<number | null>(null)

    return (
        <ContentEmbedding title={"Accounting"}>
            <Section>
                <SectionHeader>Open Payouts</SectionHeader>
                <SectionContent>
                    <APIDatatable
                        endpoint={"/payouts?only_unpaid=1"}
                        onRowClicked={(payout: Payout) => payout.id ? setPayoutId(payout.id) : setPayoutId(null) }
                        columns={changedStandardColumns}
                        modelConstructor={(init) => new Payout(init)}
                    />
                </SectionContent>


                <br />

                {payoutId ? <ProcessPayoutForm payoutId={payoutId} /> : null }

            </Section>
        </ContentEmbedding>
    )
}

export default Accounting