import React, {ReactElement, useState} from "react"
import DisplayInformationList from "../../common/Content/DisplayInformationList";
import {useParams} from "react-router-dom"
import useGetAPI from "../../../http/hooks/useGetAPI";
import ContentEmbedding from "../../common/Content/ContentEmbedding";
import Ticket, {singleResourceDisplay} from "../../../models/Ticket";
import Section, {SectionContent, SectionHeader} from "../../common/Content/Section";
import TicketMessage from "../../../models/TicketMessage";
import APIBuffering from "../../common/APIBuffering";
import performPostRequest from "../../../http/functions/performPostRequest";
import { useTranslation } from "react-i18next"
import performPutRequest from "../../../http/functions/performPutRequest";
import {useHistory} from "react-router-dom"
import fileToBase64 from "../../../utils/files/fileToBase64";
import DownloadButtonViaBlob from "../../common/DownloadButtonViaBlob";

const TicketSingle: React.FC<{}> = () => {
    const { id } = useParams()
    const { t } = useTranslation("general")
    const history = useHistory()

    // Sending doesn't describe the that it is being sent but rather this is being prepared to be sent so this would be current valued if clicked on the send button
    const [sendingMessage, setSendingMessage] = useState<string>("");
    const [sendingFile, setSendingFile] = useState<string | null>(null)
    const [sendingFileName, setSendingFileName] = useState<string | null>(null)

    const ticketGetAPI = useGetAPI<Ticket>("/tickets/" + id)
    const ticket = ticketGetAPI.responseBody ? new Ticket(ticketGetAPI.responseBody) : new Ticket({})

    const ticketMessageGetAPI = useGetAPI<TicketMessage[]>("/tickets/" + id.toString() + "/ticket-messages")
    const ticketMessages: TicketMessage[] = []



    if(ticketMessageGetAPI.responseBody)
        ticketMessageGetAPI.responseBody.forEach((singleTicketMessage) => {
            ticketMessages.push(new TicketMessage(singleTicketMessage))
        })

    const buttonText = ticket.solved ? "mark_as_unsolved" : "mark_as_solved"
    const buttonStyle = ticket.solved ? "btn btn-outline-danger" : "btn btn-outline-success"

    return (
        <React.Fragment>
            <ContentEmbedding title={"Ticket Overview"} {...ticketGetAPI} >
                <Section>
                    <SectionContent>
                        <DisplayInformationList
                            structure={singleResourceDisplay}
                            instance={ticket}
                            translationPath={"ticket"}
                        />
                        <br />
                        <div style={{ textAlign: "center"}}>
                            <input type={"button"} value={t("single_resource_pages:ticket."+buttonText).toString()} className={buttonStyle} onClick={async () => {
                                const success = await performPutRequest("/tickets/"+ticket.id, {})

                                //Refresh the page
                                if(success) {
                                    const currentLocation = history.location
                                    history.push("/reload")
                                    history.replace(currentLocation)
                                }
                            }} />
                        </div>
                    </SectionContent>
                </Section>

                <br/>


                <Section>
                <SectionHeader>Messages</SectionHeader>
                    <SectionContent>
                        <APIBuffering {...ticketMessageGetAPI}>

                                <textarea className={"form-control"} placeholder={"Your message"} onChange={(event) => {
                                    setSendingMessage(event.currentTarget.value)
                                }} value={sendingMessage} />

                                <br/>

                            <input type={"file"} onChange={ async (event) => {
                                const file = event.target.files![0]
                                const base64File: any = await fileToBase64(file)
                                setSendingFile(base64File)
                                setSendingFileName(file.name)
                            }}/>

                                <div style={{
                                    textAlign:"right",
                                }}>
                                    <input type={"button"} className={"btn btn-outline-primary"} value={t("form_actions.send").toString()} onClick={async () => {
                                        const {success} = await performPostRequest("/tickets/" + id + "/ticket-messages", {
                                            message: sendingMessage,
                                            attachment: sendingFile,
                                            attachment_name: sendingFileName,
                                        })
                                        if(success) {
                                            setSendingMessage("")
                                            ticketMessageGetAPI.resend()
                                        }
                                    }}/>
                                </div>
                                <br/>

                            {renderMessages(ticketMessages)}

                        </APIBuffering>
                    </SectionContent>
                </Section>




            </ContentEmbedding>
        </React.Fragment>
    )

    function renderMessages(messages: TicketMessage[]): ReactElement[] {
        const result: ReactElement[] = []

        messages.forEach((message) => {
            const formatted = message.messageOverviewDisplay();
            result.push(
                <div className={"card card-body bg-primary tx-white bd-0"}>
                    <p className={"card-text"}>
                        <b>Sent by {formatted.sender && formatted.sender.fullName ? formatted.sender.fullName : "Unkown" } at {formatted.created_at}</b>:
                        <br/>
                        <br/>
                        {formatted.message}
                        { formatted.attachment && formatted.attachment_name ? <><br/> {formatted.attachment_name} <DownloadButtonViaBlob file={formatted.attachment} filename={formatted.attachment_name} /> </> : null }
                    </p>
                </div>
            )
            result.push(<br/>)
        })

        return result
    }

}


export default TicketSingle