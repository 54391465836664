import React, {useState} from "react"
import axios from "../../http/axios/apiv1";

interface PropsI {
    endpoint: string
    body?: object
    filename: string
}

const DownloadButton: React.FC<PropsI> = (props: PropsI) => {

    const body = props.body ? props.body : {}

    // Solution from https://jetrockets.pro/blog/l8dadq8oac-how-to-download-files-with-axios
    return (
        <input className={"btn btn-success"} value={"Download"} type={"button"} onClick={async () => {
            //@ts-ignore
            await axios.post(props.endpoint, body, {headers: {Accept: '*'}, timeout: 30000 ,responseType: "blob"}).then(({data, headers}) => {

                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', props.filename ? props.filename : "unnamed_file");
                document.body.appendChild(link);
                link.click();
                link.remove();
            })

        }} />
    )
}

export default DownloadButton