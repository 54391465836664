import NavigationIcons from "../../../types/NavigationIcons"
import TicketList from "../../resources/List/TicketList";
import Root from "../../pages/Root";
import Shops from "../../pages/Shops";
import Conflicts from "../../pages/Conflicts";
import Statistics from "../../pages/Statistics";
import Integration from "../../pages/Integration";

const userLinks: Array<MenuLinkDescription> = [
    {identifier: "home", icon: NavigationIcons.House, path: "/", component: Root},
    {identifier: "shop", icon: NavigationIcons.ShoppingCart, path:"/shops", component: Shops},
    {identifier: "ticket", icon: NavigationIcons.Email, path: "/tickets", component: TicketList},
    {identifier: "conflict", icon: NavigationIcons.ExclamationMark, path: "/conflicts", component: Conflicts},
    {identifier: "statistic", icon: NavigationIcons.PieChart, path: "/statistics", component: Statistics},
    {identifier: "integration", icon: NavigationIcons.CogWheel, path: "/integration", component: Integration}
]



export default userLinks