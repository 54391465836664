import React from "react"
import fileToBase64 from "../../../utils/files/fileToBase64";
import performPostRequest from "../../../http/functions/performPostRequest";
import { useHistory } from "react-router-dom";

interface PropsI {
    // Specify exactly one of the following two
    shopId?: number
    userId?: number
}

const AddVerificationFileForm: React.FC<PropsI> = (props: PropsI) => {

    let endpoint: string | null = null

    const history = useHistory()

    if(props.shopId)
        endpoint = "/shops/" + props.shopId.toString()
    else if(props.userId)
        endpoint = "/users/" + props.userId.toString()

    if(endpoint)
        return (
            <input type={"file"} onChange={ async (event) => {
                const file = event.target.files![0]
                const base64File = await fileToBase64(file)
                const success = await performPostRequest( endpoint + "/verification-files", {file_name: file.name, file: base64File})
                if(success) {
                    // To update the list below it in an easy way
                    history.push("/temp")
                    history.goBack()
                }

            }}/>
        )

    return <React.Fragment />
}


export default AddVerificationFileForm