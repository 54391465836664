import React, { useContext } from "react";
import PermissionContext from "../../../contexts/PermissionContext";
import getPermissionFromMap from "../../../utils/auth/getPermissionFromMap";
import translateListOfObjects from "../../../utils/translation/translateListOfObjects";
import { useTranslation } from "react-i18next"
import Section, {SectionContent, SectionHeader} from "../../common/Content/Section";
import SubResourceInterface from "./SubResourceInterface";

interface PropsI extends SubResourceInterface{
    title: string
    subResource: React.ComponentType<SubResourceInterface>
    permissionRequired: string
}


const SubListResourceEmbedding: React.FC<any> = <T extends PropsI>(props: T) => {
    const permissionMap = useContext(PermissionContext);

    const {t} = useTranslation()
    translateListOfObjects(props.standardColumns, "selector", "name", t, props.keyPrefix + ".")

    if(getPermissionFromMap(permissionMap, props.shopId, props.permissionRequired))
        return (
            <Section>
                <SectionHeader>{props.title}</SectionHeader>
                <SectionContent>
                    {<props.subResource {...props} />}
                </SectionContent>
            </Section>
        )

    return null
}

export default SubListResourceEmbedding