import React, {ReactElement} from "react"
import useGetAPI from "../../../http/hooks/useGetAPI";
import APIBuffering from "../../common/APIBuffering";
import Section, {SectionContent, SectionHeader} from "../../common/Content/Section";
import { useTranslation } from "react-i18next";

interface PropsI {
    shopId: number
}

const ShopSettingLog: React.FC<PropsI> = (props: PropsI) => {

    const {t} = useTranslation()

    const getAPI = useGetAPI<any[]>("/shops/"+ props.shopId.toString() + "/versions")

    return (
        <Section>
            <SectionHeader>Setting Change Log</SectionHeader>
            <SectionContent>
                <APIBuffering {...getAPI}>
                    {renderLog()}
                </APIBuffering>
            </SectionContent>
        </Section>
    )


    function renderLog(): ReactElement[] {
        const log: ReactElement[] = []

        const data = getAPI.responseBody
        if(data) {
            data.forEach((changeSet: object) => {
                for(const [key, value] of Object.entries(changeSet))
                    log.push(
                        <p style={{border: "solid", padding: "10px"}}>
                            <h6 style={{color: "white"}}>{t("single_resource_pages:shop."+key)}</h6>
                            <div>{value ? value.toString() : "None"}</div><br />
                        </p>
                    )
            })

        }

        return log.map((formattedChangeSet: any) => {
            return <React.Fragment>
                <div>
                {formattedChangeSet}
                </div>
                <br />
            </React.Fragment>
        })
    }
}


export default ShopSettingLog