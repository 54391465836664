import React from "react"
import User from "../../../models/User";
import NotificationDropDown from "./NotificationDropDown";
import UserProfileDropDown from "./UserProfileDropDown"
import Search from "./Search";

interface PropsI{
    user: User | null
}

const HeadBar: React.FC<PropsI> = (props: PropsI) => {

    return(
        <div className={"br-header"}>
            <div className={"br-header-left"}>
                <Search />
            </div>
            <div className={"br-header-right"}>
                <div className={"nav"}>
                    {props.user ? <NotificationDropDown /> : null }
                    {props.user ? <UserProfileDropDown user={props.user} /> : null }
                </div>
            </div>
        </div>
    )
}

export default HeadBar