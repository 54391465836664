import {Switch} from "react-router-dom";
import React, {ReactElement} from "react";
import UserRoutes from "../../../routes/UserRoutes";
import GuestRoutes from "../../../routes/GuestRoutes";
import User from "../../../models/User";
import AdminRoutes from "../../../routes/AdminRoutes";
import guestLinks from "../Navigation/GuestLinks";
import adminLinks from "../Navigation/AdminLinks";
import inactiveUserLinks from "../Navigation/InactiveUserLinks";
import userLinks from "../Navigation/UserLinks";
import InactiveUserRoutes from "../../../routes/InactiveUserRoutes";

interface PropsI {
    loggedIn: boolean
    user: User | null
}

const Content = (props: PropsI) => {

    const design = props.loggedIn ? "br-mainpanel" : "d-flex align-items-center justify-content-center ht-100v"

    return(
        <div className={design}>
                <Switch>
                    { renderRouteDefinitions(props.loggedIn, props.user)}
                </Switch>
        </div>
    )
}

/*
 * This will make routes available according to the login states
 */
function renderRouteDefinitions(loggedIn: boolean, user: User | null): ReactElement {

    if(loggedIn)
        if(user) {
            if (user.is_admin)
                return <AdminRoutes />
            else if(user.state !== "ACTIVE")
                return <InactiveUserRoutes />
            else
                return <UserRoutes />
        }

    return <GuestRoutes />
}


export default Content