import Permission from "../../models/Permission";
import axios from "../../http/axios/apiv1";

async function retrievePermission(): Promise<Map<number,Permission>|null> {
    return await axios.get<Permission[]>("/users/current/permissions").then(({data}) => {

        const map = new Map<number, Permission>()

        data.forEach((singlePermission) => {
            map.set(singlePermission.shop_id, singlePermission)
        })

        return map
    }).catch(() => {
        return null
    })

}

export default retrievePermission