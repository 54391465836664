import React, {ReactElement} from "react";
import {Route} from "react-router-dom"

function loadRouteFromMenuLinkDescription(routeInformation?: Array<Partial<MenuLinkDescription>>): Array<ReactElement> {

    if(!routeInformation)
        return []

    const routes: Array<ReactElement> = []
    routeInformation.forEach((route:  Partial<MenuLinkDescription>) => {
        if(route.path && route.component)
            routes.push(<Route key={route.identifier} exact path={route.path} component={route.component} />)

        if(route.subMenus)
            route.subMenus.forEach((subMenu: SubMenuLinkDescription) => {
                routes.push(<Route key={route.identifier} exact path={subMenu.path} component={subMenu.component} />)
            })

    })

    return routes
}

export default loadRouteFromMenuLinkDescription