import React, {CSSProperties, ReactElement, useRef, useState} from "react"
import UserNotification from "../../../models/UserNotification";
import useRefreshingGetAPI from "../../../http/hooks/useRefreshingGetAPI";
import dateFromIso8601ToLocal from "../../../utils/time/dateFromIso8601ToLocal";
import axios from "../../../http/axios/apiv1";
import useOnClickOutside from "use-onclickoutside";
import { useTranslation } from "react-i18next";

const NotificationDropDown: React.FC = () => {

    const {t} = useTranslation("general")

    // Every ten seconds
    const {responseBody: notifications, resend} = useRefreshingGetAPI<UserNotification[]>("/user-notifications", 10*1000);
    const [showDropDown, setShowDropDown] = useState<boolean>(false)

    const anyUnseen = unseenExists();
    const style: CSSProperties = showDropDown ? {position: "absolute", display: "block", float: "right"} : {}

    const dropDownRef = useRef(null)
    useOnClickOutside(dropDownRef, () => setShowDropDown(false))

    return (
        <div className={"dropdown show"}>
            <a className="nav-link pd-x-7 pos-relative" onClick={() => {
                setShowDropDown(!showDropDown)
            }}>
                <i className="icon ion-ios-bell-outline tx-24" />

                { anyUnseen ? <span className="square-8 bg-danger pos-absolute t-15 r-5 rounded-circle" /> : null}
                <div className="dropdown-menu dropdown-menu-header" style={style} ref={dropDownRef}>
                    <div className="dropdown-menu-label">
                        <label>{t("layout_element.notifications")}</label>
                        <input type={"button"} className={"btn btn-success"} value={t("form_actions.mark_all_as_read").toString()} onClick={() => {
                            if(anyUnseen) {
                                markAllNotificationsAsRead()
                                resend()
                            }
                        }} />
                    </div>
                    {renderNotifications()}
                </div>
            </a>
        </div>
    )

    function unseenExists(): boolean {
        if(notifications)
            return notifications.some((singleNotification) => {
                return !singleNotification.seen
            })

        return false
    }

    function renderNotifications(): ReactElement[] {
        let result: ReactElement[] = []

        if(notifications)
            notifications.forEach((singleNotification) => {
                result.push(
                    <div className="media-list">
                        <a href="" className="media-list-link read">
                            <div className="media">
                                <img src="https://via.placeholder.com/500" alt="" />
                                <div className="media-body">
                                    <p className="noti-text">{singleNotification.message}</p>
                                    <span>{dateFromIso8601ToLocal(singleNotification.created_at, [], {hour: '2-digit', minute: '2-digit'})}</span>
                                </div>
                            </div>
                        </a>
                    </div>
                )
            })


        return result

    }

    $(document)

    async function markAllNotificationsAsRead(): Promise<void> {
        await axios.put("/user-notifications")
    }

}

export default NotificationDropDown