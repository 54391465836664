import NavigationIcons from "../../../types/NavigationIcons"
import userLinks from "./UserLinks";
import Users from "../../pages/Users";
import PaymentTransactions from "../../pages/PaymentTransactions";
import Accounting from "../../pages/Accounting";
import Review from "../../pages/Review";

// Includes userlinks and adds more on top.
const adminLinks: Array<MenuLinkDescription> = userLinks.concat([
    {identifier: "memberlist", icon: NavigationIcons.Person, path: "/users", component: Users},
    {identifier: "payment_transaction_overview", icon: NavigationIcons.List, path: "/payment-transactions", component: PaymentTransactions},
    {identifier: "accounting", icon: NavigationIcons.OpenBook, path: "/accounting", component: Accounting},
    {identifier: "awaiting_review", icon: NavigationIcons.Filing, path: "/review", component: Review}
])

export default adminLinks