import React from "react"
import Section, {SectionContent} from "../../common/Content/Section";
import DisplayInformationList from "../../common/Content/DisplayInformationList";
import User, {singleResourceDisplay} from "../../../models/User";
import APIBuffering from "../../common/APIBuffering";
import useGetAPI from "../../../http/hooks/useGetAPI";

const UserSingle: React.FC<{}> = () => {

    const getAPI = useGetAPI<User>("/users/current")


    return (
        <Section>
            <APIBuffering {...getAPI}>
                <SectionContent>
                    <DisplayInformationList
                        structure={singleResourceDisplay}
                        instance={getAPI.responseBody ? new User(getAPI.responseBody) : new User({})}
                        editable={true}
                        putEndPoint={"/users/current"}
                        translationPath={"user"}
                    />
                </SectionContent>
            </APIBuffering>
        </Section>
    )
}

export default UserSingle