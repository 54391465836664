import React, {useState} from "react";
import performPostRequest from "../../http/functions/performPostRequest";
import {Link, useHistory} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const AuthRegister: React.FC<{}> = () => {

    const [email, setEmail] = useState<string>("")
    const [firstname, setFirstname] = useState<string>("")
    const [lastname, setLastname] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [recaptchaResponse, setRecaptchaResponse] = useState<string>("")

    const history = useHistory()

    return (
        <div className={"login-wrapper wd-300 wd-xs-400 pd-25 pd-xs-40 bg-br-primary bd bd-white-1 rounded"}>
            <div className={"signin-logo tx-center tx-28 tx-bold tx-white"}>Alma2</div>

            {/* The subtitle is left empty here for the spacing it introduces. A subtitle can go in between the divs*/}
            <div className={"tx-center mg-b-60"}></div>

            <div className={"form-group"}>
                <input className={"form-control form-control-dark"} type="text" onChange={(event) => setEmail(event.currentTarget.value)} value={email} placeholder={"Enter your Email"}/>
            </div>

            <div className={"form-group"}>
                <input className={"form-control form-control-dark"} type="text" onChange={(event) => setFirstname(event.currentTarget.value)} value={firstname} placeholder={"Enter your first name"}/>
            </div>

            <div className={"form-group"}>
                <input className={"form-control form-control-dark"} type="text" onChange={(event) => setLastname(event.currentTarget.value)} value={lastname} placeholder={"Enter your last name"}/>
            </div>

            <div className={"form-group"}>
                <input className={"form-control form-control-dark"} type="password" onChange={(event) => setPassword(event.currentTarget.value)} value={password} placeholder={"Enter your password"}/>
            </div>

            <div className={"form-group"}>
                <input className={"form-control form-control-dark"} type="password" onChange={(event) => setConfirmPassword(event.currentTarget.value)} value={confirmPassword} placeholder={"Confirm your password"}/>
            </div>

            <div className={"form-group"}>
                <ReCAPTCHA
                    sitekey={"6LfvkWUUAAAAAMnAHCA9tHqR2IXFrM3xPzdcR_7h"}
                    onChange={(value) => {
                        if(value)
                            setRecaptchaResponse(value)
                        else
                            setRecaptchaResponse("")
                    }}
                />
            </div>

            <div className={"form-group tx-12"}>
                <button disabled={email === "" || firstname === "" || lastname === "" || password === "" || confirmPassword === "" || password !== confirmPassword ||recaptchaResponse === "" } className={"btn btn-info btn-block"} onClick={async() => {
                    if(password === confirmPassword) {
                        const success = await performPostRequest("/register", {
                            email, firstname, lastname, password,
                            recaptcha_response: recaptchaResponse,
                        })

                        if (success)
                            history.push("/")
                    }
                }}>Register</button>
            </div>

            <div className={"mg-t-60 tx-center"}>
                Already a member? <Link className={"tx-info"} to={"/"}>Login</Link>
            </div>
        </div>
    )
}

export default AuthRegister