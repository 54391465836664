import React from "react"
import UserSingle from "../../resources/Single/UserSingle";
import ChangePasswordForm from "../../resources/Form/ChangePasswordForm";
import ContentEmbedding from "../../common/Content/ContentEmbedding";
import Section, {SectionContent, SectionHeader} from "../../common/Content/Section";

export const AccountInformation: React.FC<{}> = () => {

    return (
        <ContentEmbedding>
            <UserSingle/>
            <br />
            <Section>
                <SectionHeader>Change password</SectionHeader>
                <SectionContent>
                    <ChangePasswordForm/>
                </SectionContent>
            </Section>
        </ContentEmbedding>
    )
}

export default AccountInformation