import React, {ReactElement, useState} from "react"
import { useTranslation } from "react-i18next"
import {availableLanguages} from "../../../i18n";

const LanguageSelector: React.FC<{}> = () => {
    const {t, i18n} = useTranslation("languages")
    const currentLanguage = i18n.language

    return(
        <form>
            <select className="form-control select2" onChange={(event) => {
                i18n.changeLanguage(event.currentTarget.value)
            }}>
                {renderLanguageOptions()}
            </select>
        </form>

    )


    function renderLanguageOptions(): ReactElement[] {
        return availableLanguages.map((language: string) => {
            // the languages.json should only be defined in en as it is the fallback language and languages should be displayed in their own language
            return (
                <option value={language} selected={currentLanguage === language}>{t("languages:" + language)}</option>
            )
        })
    }
}

export default LanguageSelector