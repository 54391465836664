import React, {useEffect, useState} from "react"
import ContentEmbedding from "../common/Content/ContentEmbedding";
import Section, {SectionContent} from "../common/Content/Section";
import DownloadButton from "../common/DownloadButton";
import APIDatatable from "../common/APIDatatable/APIDatatable";
import PaymentTransaction, {standardColumns} from "../../models/PaymentTransaction";
import {useTranslation} from "react-i18next";
import translateListOfObjects from "../../utils/translation/translateListOfObjects";
import AddConflictForm from "../resources/Form/AddConflictForm";


const PaymentTransactions: React.FC<{}> = () => {

    const { t } = useTranslation("table_header")
    translateListOfObjects(standardColumns, "selector", "name", t,"payment_transactions.")


    const [selectedArray, setSelectedArray] = useState<string[]>([]);
    const [uuid, setUUID] = useState<string>("");

    return <ContentEmbedding title={"Payment Transaction"}>
        <Section>
            <SectionContent>
                <DownloadButton endpoint={"/payment-transactions/pdf"} filename={"payment-transaction-overview.pdf"}
                    body={{
                        exactSearch: {uuid: selectedArray}
                    }}
                />
                &nbsp;&nbsp;
                Currently {selectedArray.length} Elements selected

                <br />
                <br />

                <APIDatatable
                    endpoint={"/payment-transactions"}
                    columns={standardColumns}
                    rowsPerPage={30}
                    pagination={true}
                    onRowClicked={(row) => setUUID(row.uuid)}
                    noContextMenu={true}
                    paginationRowsPerPageOptions={[10, 30, 50, 100, 200, 300, 500]}
                    keyField={"uuid"}
                    modelConstructor={(init) => new PaymentTransaction(init)}
                    getCurrentSelection={(selected) => setSelectedArray(Array.from(selected))}
                    selectableRows
                    searchString
                />

                <br/>
                <br/>
                <br/>

                {uuid !== "" ? <AddConflictForm paymentTransactionUUID={uuid} onResponse={(success) => success ? setUUID("") : null}/>: null}
            </SectionContent>
        </Section>
    </ContentEmbedding>

}

export default PaymentTransactions