import cloneObject from "./cloneObject";

function removeKeysWithEmptyString<T extends object, K extends keyof T>(obj: T) {

    const clone = cloneObject(obj)

    for (let propName in clone) {
        // No idea what this next line's error is since the statement is just wrong
        // @ts-ignore
        if (clone[propName] === "")
            delete clone[propName]
    }

    return clone
}

export default removeKeysWithEmptyString;