// Solution from here https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript

// Unsure how to properly type this
const fileToBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export default fileToBase64