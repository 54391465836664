import React from "react"
import axios from "./http/axios/apiv1"
import csrfAxios from "./http/axios/csrf"
import {attemptLoadingSavedAuth, login} from "./utils/auth/authController"
import retrievePermission from "./utils/auth/retrievePermission";
import retrieveUser from "./utils/auth/retrieveUser";

import Permission from "./models/Permission";
import User from "./models/User";

import PermissionContext from "./contexts/PermissionContext";

import Navigation from "./components/common/Navigation/Navigation"
import HeadBar from "./components/common/HeadBar/HeadBar"
import Content from "./components/common/Content/Content"

import "./design/lib/@fortawesome/fontawesome-free/css/all.min.css"
import "./design/lib/ionicons/css/ionicons.min.css"
import "./design/lib/highlightjs/styles/dracula.css"
import "./design/lib/spinkit/css/spinkit.css"
import "./design/lib/select2/css/select2.min.css"
import "bootstrap"


import "./design/css/bracket.css"
import "./design/css/bracket.dark.css"
import UserContext from "./contexts/UserContext";

interface StateI {
    loggedIn: boolean;
    user: User | null;
    permission: Map<number, Permission> | null;
}

class App extends React.Component<{},StateI> {

    render() {
        if(this.state.loggedIn)
            return (
                <PermissionContext.Provider value={this.state.permission}>
                    <UserContext.Provider value={this.state.user}>
                        <React.Fragment>
                            <Navigation {...this.state} />
                            <HeadBar {...this.state} />
                            <Content {...this.state} />
                        </React.Fragment>
                    </UserContext.Provider>
                </PermissionContext.Provider>
            )

        return (
            <Content {...this.state} />
        )
    }



    constructor(props: {}) {
        super(props);

        csrfAxios.get("")

        // Persisting and reloading the token. Otherwise the user has to log in again on reload.
        const loginResponse = attemptLoadingSavedAuth()

        if(loginResponse)
            this.state = { loggedIn: true, user: loginResponse.user, permission: null}
        else
            this.state = { loggedIn: false, user: null, permission: null}

        // This will ensure that an invalid bearer token will lead to clearing of such a token

        const impersonating = localStorage.getItem("impersonating")
        if(impersonating)
            axios.defaults.headers.common["Impersonate"] = impersonating


        axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('login-response')
                    window.location.reload()
                }
                return Promise.reject(error)
            }
        )

    }

    public async componentDidMount() {

        // Permission need to be set up still
        let permission = null
        let user = null
        if(this.state.loggedIn) {
            permission = await retrievePermission()
            user = await retrieveUser()

        }

        if(user && user !== this.state.user) {
            const loginResponse = attemptLoadingSavedAuth()
            if(loginResponse) {
                loginResponse.user = user
                localStorage.setItem("login-response", JSON.stringify(loginResponse))
            }
            this.setState({permission, user})
        }

        this.setState({permission})
    }

}


export default App
