import React from "react"
import {useTranslation} from "react-i18next"
import LoadingScreen from "./Filler/LoadingScreen";

interface PropsI {
    loading?: boolean
    failed?: boolean
    // Should be ReactNode but it will throw an error using it.
    children: any
}

const APIBuffering: React.FC<PropsI> = (props: PropsI) => {

    const {t} = useTranslation("general")


    if(props.failed === true)
        return <React.Fragment>
            {t("get_api_states.failed_to_load")}
        </React.Fragment>

    if(props.loading === true)
        return <LoadingScreen />

    return props.children

}

export default APIBuffering