import React from "react"
import LoginList from "../resources/List/LoginList";
import ContentEmbedding from "../common/Content/ContentEmbedding";
import Section, {SectionContent, SectionHeader} from "../common/Content/Section";
import TwoFASetup from "../auth/TwoFASetup";
import useGetAPI from "../../http/hooks/useGetAPI";

const Security: React.FC<{}> = () => {

    return (
        <ContentEmbedding title={"Security"}>
            <Section>
                <SectionHeader>Login Log</SectionHeader>
                <SectionContent>
                    <LoginList />
                </SectionContent>
            </Section>

            <br />

            <Section>
                <SectionHeader>Set Up 2FA</SectionHeader>
                <SectionContent>
                    <TwoFASetup />
                </SectionContent>
            </Section>
        </ContentEmbedding>
    )
}

export default Security