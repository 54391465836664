import React, {ReactElement, useState} from "react"
import {uid} from "react-uid";
import { useTranslation } from "react-i18next"
import {
    isInstanceOfAPISelectDescription,
    isInstanceOfBooleanRadioEditableDescription
} from "../../../types/typeguards/EditableTypes";
import produce from "immer"
import performPutRequest from "../../../http/functions/performPutRequest";
import dateFromIso8601ToLocal from "../../../utils/time/dateFromIso8601ToLocal";
import stringifyInnerObjects from "../../../utils/typing/stringifyInnerObjects";
import axios from "../../../http/axios/apiv1";
import APISelect from "../FormElement/APISelect";
import performDeleteRequest from "../../../http/functions/performDeleteRequest";
import { useHistory } from "react-router-dom";

interface PropsI {
    structure: Array<SingleResourceDisplayStructure>
    instance: object | Array<any>
    // default table_header
    namespace?: string

    // default empty; should not end with a dot (.)
    translationPath?: string

    // default no
    editable?: boolean
    deletable?: boolean

    // It cannot save changes without this field. Should only be left undefined if no field should ever be editable.
    putEndPoint?: string
    deleteEndpoint?: string

}

const DisplayInformationList: React.FC<PropsI> = (props: PropsI) => {

    const history = useHistory()
    const {t} = useTranslation(props.namespace ? props.namespace : "single_resource_pages")
    const [editing, setEditing] = useState<boolean>(false)
    const [instance, setInstance] = useState<any>(stringifyInnerObjects(props.instance))
    const [editedInstance, setEditedInstance] = useState<any>(instance)
    const [loading, setLoading] = useState<boolean>(false)

    const translationPath = props.translationPath ? props.translationPath + "." : ""


    let transformedValues: any = {}
    props.structure.forEach((singleRow) => {

        const identifier: string = singleRow.identifier
        let value: any = instance[identifier]
        const valueTransform = singleRow.valueTransform

        // When editing and leaving an empty string it would leave the empty string.
        // It should be right away as no value given.
        if(value==="")
            value = null

        // This should fetch null and undefined values which can lead to errors below.
        // When defining an own function for the transform handling then the null or undefined can and should be interpreted in the function
        if(value == null && typeof(singleRow.valueTransform) !== "function")
            transformedValues[identifier] = value


        /*
         * Standard valueTransforms
         */
        else if(!valueTransform)
            transformedValues[identifier] = value
        else if(valueTransform === "BooleanYesNo")
            transformedValues[identifier] = value ? t("boolean:YesNo.true") : t("boolean:YesNo.false")
        else if(valueTransform === "DateAndTime")
            transformedValues[identifier] = dateFromIso8601ToLocal(value, [], {hour: '2-digit', minute: '2-digit'})
        else if(valueTransform === "ClickableLinkNewTab")
            transformedValues[identifier] = <a href={value} target={"_blank"} rel="noopener noreferrer">{value}</a>
        else if(typeof(singleRow.valueTransform) === "function")
            // Typescript does not recognize it cannot be a string.
            // @ts-ignore
            transformedValues[identifier] = valueTransform(value)
        else
            transformedValues[identifier] = value
    })

    // This could be filtered in the second forEach but this would make things more complicated and duplicate since it needs logic for the switch to editing.
    // This is also excluding undefined values. These indicate that they were not send in the response. Not even with null. Indicating missing permissions or an error somewhere else
    // This is checked via !== undefined because the models contructor will assign the value with undefined making it not possible to check via (singleRow.identifier in transformedValues)
    const visibleFields: SingleResourceDisplayStructure[] = props.structure.filter((singleRow) => {
        // @ts-ignore
        return ( (transformedValues[singleRow.identifier] !== undefined) && (singleRow.onEmpty || transformedValues[singleRow.identifier] !== null))
    })

    const anythingToEdit: boolean = visibleFields.filter((singleRow) => {
        return singleRow.editable
    }).length !== 0

    const rows: ReactElement[] = []
    visibleFields.forEach(async (singleRow) => {
        // @ts-ignore
        const currentField: any = transformedValues[singleRow.identifier]
        const editedCurrentField: any = editedInstance[singleRow.identifier]

        /*
         * ASSIGNING THE ROWS
         */
        if(currentField != null || singleRow.onEmpty) {

            // LEFT HAND SIDE LABEL. AUTOMATIC TRANSLATION
            const label = t(translationPath + singleRow.identifier)


            // SETTING THE VALUE. TAKING INTO CONSIDERATION EMPTY VALUES AND EDITING MODUS
            let value: string | ReactElement | null

            /*
             * APPEARS IF "EDIT" WAS PRESSED
             */
            if(editing && singleRow.editable) {

                // NORMAL ONE LINE TEXT FIELD
                if(singleRow.editable === "text")
                    // @ts-ignore
                    value = <input type={"text"} className={"form-control"} value={editedInstance[singleRow.identifier]} onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        ev.persist()
                        setEditedInstance(produce((draft) => {
                            draft[singleRow.identifier] = ev.target.value
                        }))
                    }}/>

                // TEXTAREA
                if(singleRow.editable === "textarea")
                    // @ts-ignore
                    value = <textarea className={"form-control"} value={editedInstance[singleRow.identifier]} onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        ev.persist()
                        setEditedInstance(produce((draft) => {
                            draft[singleRow.identifier] = ev.target.value
                        }))
                    }}/>


                // BOOLEAN RADIO BUTTONS
                else if(isInstanceOfBooleanRadioEditableDescription(singleRow.editable))
                    value = <form>

                        {/* TRUE */}
                        <input type={"radio"} name={"boolean"} checked={editedCurrentField} onChange={(ev) => {
                            setEditedInstance(produce(editedInstance, (draft: any) => {
                                draft[singleRow.identifier] = true
                            }))
                        }}/> {singleRow.editable.optionTrue}

                        {/* FALSE */}
                        <input type={"radio"} value={"false"} checked={!editedCurrentField} name={"boolean"} onChange={(ev) => {
                            setEditedInstance(produce(editedInstance, (draft: any) => {
                                draft[singleRow.identifier] = false
                            }))
                        }}/> {singleRow.editable.optionFalse}
                    </form>

                // API Select
                 else if(isInstanceOfAPISelectDescription(singleRow.editable)) {
                    value = <APISelect onChange={(event) => {
                        setEditedInstance(produce(editedInstance, (draft: any) => {
                            draft[singleRow.identifier] = event.currentTarget.value
                        }))
                    }} {...singleRow.editable}/>
                }

                // DEFAULT FALLBACK IF VALUES DON'T MAKE SENSE
                else
                    value= currentField

            /*
             * APPEARS INITIALLY IN NON EDITING MODUS
             */
            } else {
                if (!currentField && singleRow.onEmpty)
                    value = singleRow.onEmpty
                else
                    value = currentField
            }




            /*
             * PUSHING THE LABEL AND VALUES WHICH HAVE BEEN GENERATED ABOVE INTO THE LAYOUT
             */
            rows.push(
                <div key={uid(singleRow)} className={"row no-gutters"}>
                    <div className={"col-5 col-sm-4"}> {label} </div>
                    <div className={"col-7 col-sm-8"}> {value} </div>
                </div>
            )
        }
        //===========END ROW GENERATION===============================



    })

    let button: ReactElement | null

    if(props.editable && anythingToEdit)
    //IF ANY PROPERTY IS EDITABLE AND EDIT PERMISSION ARE GIVEN
        if(editing) {
            const cancel: string = t("general:cancel")
            const save: string =  !loading ? t("general:save") : t("general:saving")
            // IN EDITING MODUS
            button = <React.Fragment>
                {/* CANCEL BUTTON */}
                <input
                    type={"button"}
                    value={cancel}
                    className={"btn btn-danger"}
                    onClick={() => {
                        setEditedInstance(instance)
                        setEditing(false)
                    }}
                />

                {/* SAVING BUTTON */}
                <input
                    type={"button"}
                    value={save}
                    disabled={instance === editedInstance}
                    className={"btn btn-success"}
                    style={{marginLeft: "1em"}}
                    onClick={async () => {
                        await sendRequest()
                    }}
                />
            </React.Fragment>

        }else {
            // NOT EDITING MODUS
            /* "EDIT" BUTTON FOR ENTERING THE EDITING MODUS */
            const edit: string = t("general:editing.edit")
            button = <input
                type={"button"}
                className={"btn btn-outline-primary"}
                value={edit}
                onClick={() => setEditing(true)}
            />
        }
    else
    // IF NO PROPERTY IS EDITABLE OR NO EDIT PERMISSIONS ARE GIVEN
        button = null

    let deleteButton: ReactElement | null = null
    if(props.deletable && props.deleteEndpoint != undefined) {
        deleteButton =
            <input type={"button"} className={"btn btn-danger"} value={t("general:form_actions.delete").toString()}
                   onClick={async () => {
                       const success = await performDeleteRequest(props.deleteEndpoint!, {})
                       if (success)
                           history.goBack()
                   }}/>
    }
    return (
        <div className={"form-layout form-layout-6"}>
            {rows}
            <br />

            <div style={{textAlign: "right"}}>
                {button}
            </div>
            {deleteButton}
        </div>
    )

    async function sendRequest(): Promise<void> {
        if(props.putEndPoint) {
            setLoading(true)
            const success: boolean = await performPutRequest(props.putEndPoint, editedInstance)
            setInstance(editedInstance)
            setLoading(false)
            setEditing(!success)
        }
    }
}

export default DisplayInformationList