import React, {ReactNode} from "react"
import Error404 from "../Error/Error404";
import LoadingScreen from "../Filler/LoadingScreen";

interface PropsI {
    title?: string | ReactNode
    // meaning text below the title usually to descripe the page
    subtitle?: string | ReactNode

    // Can be set optionally to render a loading screen or 404 page
    failed?: boolean
    loading?: boolean

}


const ContentEmbedding: React.FC<PropsI> = (props) => {

    // Check explicitly since it could be undefined
    if(props.failed === true)
        return <Error404 />

    if(props.loading === true)
        return <LoadingScreen />

    return (
        <React.Fragment>
            <div className={"br-pagetitle"}>
                <div>
                    <h4>{ props.title }</h4>
                    <p className={"mg-b-0"} >
                        { props.subtitle }
                    </p>
                </div>
            </div>
            <div className={"br-pagebody"}>
                { props.children }
            </div>
        </React.Fragment>
    )
}

export default ContentEmbedding;