import React from "react"

interface PropsI {
    file: string
    filename: string
}


// Unlike the normal DownloadButton this one takes the data not the endpoint
const DownloadButtonViaBlob: React.FC<PropsI> = (props: PropsI) => {

    return (
        <input className={"btn btn-success"} value={"Download"} type={"button"} onClick={() => {
            // This is to remove a potential prefix to the blob string containing initial file type and that is has been base64 encoded.
            const base64SFileStringSplit = props.file.split(",")
            const processedFile = base64SFileStringSplit.pop();
            //@ts-ignore
            const byteCharacterFile = atob(processedFile)
            const byteNumbersFile = new Array(byteCharacterFile.length);
            for (let i = 0; i < byteCharacterFile.length; i++) {
                byteNumbersFile[i] = byteCharacterFile.charCodeAt(i);
            }
            const byteArrayFile = new Uint8Array(byteNumbersFile)

            const downloadUrl = window.URL.createObjectURL(new Blob([byteArrayFile]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', props.filename ? props.filename : "unnamed_file");
            document.body.appendChild(link);
            link.click();
            link.remove();

        }} />
        )
}

export default DownloadButtonViaBlob