import React, {useContext, useState} from "react";
import APIDatatable from "../../common/APIDatatable/APIDatatable";
import SubResourceInterface from "./SubResourceInterface";
import {useTranslation} from "react-i18next";
import translateListOfObjects from "../../../utils/translation/translateListOfObjects";
import {standardColumns} from "../../../models/Shop";
import PaymentTransaction from "../../../models/PaymentTransaction";
import UserContext from "../../../contexts/UserContext";
import AddConflictForm from "../Form/AddConflictForm";

interface PropsI extends SubResourceInterface {
    payoutId: number
}

const PaymentTransactionList: React.FC<PropsI> = (props: PropsI) => {

    const [uuid, setUUID] = useState<string>("")
    const user = useContext(UserContext)!

    const { t } = useTranslation("table_header")
    translateListOfObjects(standardColumns, "selector", "name", t,"payment_transactions.")


    return (
        <React.Fragment>

            <APIDatatable
                endpoint={"/payment-transactions?search[payout_id]=" + props.payoutId.toString()}
                columns={props.standardColumns}
                rowsPerPage={30}
                pagination={true}
                keyField={"uuid"}
                onRowClicked={(row) => setUUID(row.uuid)}
                modelConstructor={(init) => new PaymentTransaction(init)}
                searchString
            />

            <br />
            <br />

            {user.is_admin && uuid !== "" ? <AddConflictForm paymentTransactionUUID={uuid} onResponse={(success) => success ? setUUID("") : null}/> : null}

        </React.Fragment>
    )
}

export default PaymentTransactionList