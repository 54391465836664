import React, {useContext} from "react"
import Section, {SectionContent, SectionHeader} from "../common/Content/Section";
import { useTranslation } from "react-i18next";
import ContentEmbedding from "../common/Content/ContentEmbedding";
import UserContext from "../../contexts/UserContext";
import User from "../../models/User";
import AddVerificationFileForm from "../resources/Form/AddVerificationFileForm";
import DisplayExistingVerificationFiles from "../resources/subresources/DisplayExistingVerificationFiles";

const AddUserVerification: React.FC<{}> = () => {

    const {t} = useTranslation("static_page_content")

    const user: User | null = useContext(UserContext)

    if(user && user.id)
        return (
            <ContentEmbedding>
                <Section>
                    <SectionHeader>{t("verification_page.section_header")}</SectionHeader>
                    <SectionContent>
                        {t("verification_page.please_add_verification")}
                        <br />
                        {t("verification_page.needed_documents")}

                        <br />
                        <br />

                        <AddVerificationFileForm userId={user.id}/>

                        <br />
                        <br />

                        <DisplayExistingVerificationFiles userId={user.id} />
                    </SectionContent>
                </Section>
            </ContentEmbedding>
        )

    return <React.Fragment />
}

export default AddUserVerification