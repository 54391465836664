function stringifyInnerObjects(object: object): object {
    const returnObject: object = {}
    for (const [key, value] of Object.entries(object)) {
        if(value !== null && typeof(value) === "object")
            // @ts-ignore
            returnObject[key] = JSON.stringify(value)
        else
            //@ts-ignore
            returnObject[key] = value

    }

    return returnObject

}

export default stringifyInnerObjects