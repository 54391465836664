import cloneObject from "./cloneObject";

function removeKeysWithEmptyObjects<T extends object, K extends keyof T>(obj: T) {

    const clone = cloneObject(obj)

    for (let propName in clone) {
        if (clone[propName] === {})
            delete clone[propName]
    }

    return clone
}

export default removeKeysWithEmptyObjects;