export function isInstanceOfBooleanRadioEditableDescription(object?: any): object is BooleanRadioEditableDescription {
    if(object)
        return ("optionTrue" in object) && ("optionFalse" in object)
    else
        return false
}

export function isInstanceOfAPISelectDescription(object?: any): object is APISelectDescription {
    if (object)
        return ("endpoint" in object) && ("valueKey" in object) && ("displayKey" in object)
    else
        return false

}