import React, {useEffect, useState} from "react"
import DataTable, {IDataTableColumn, IDataTableProps} from "react-data-table-component";
import bracketDarkTheme from "./bracketDarkTheme";
import LoadingScreen from "../Filler/LoadingScreen";
import {useHistory} from "react-router-dom"
import useGetAPI from "../../../http/hooks/useGetAPI";
import APIBuffering from "../APIBuffering";
import removeKeysWithNullValue from "../../../utils/objects/removeKeysWithNullValue";
import removeKeysWithEmptyObjects from "../../../utils/objects/removeKeysWithEmptyObjects";
import removeKeysWithEmptyString from "../../../utils/objects/removeKeysWithEmptyString";
import cloneObject from "../../../utils/objects/cloneObject";
import { useTranslation } from "react-i18next";

const qs = require("qs")

interface PropsI extends Partial<IDataTableProps<any>> {
    endpoint: string
    // It will default to add the number to the current route if not set. otherwise it will append it to this route
    onClickTarget?:string
    columns: Array<IDataTableColumn<any>>
    page?: number
    rowsPerPage?: number
    keyField?: string
    searchString?: boolean



    // See PayoutList under subresources for an example of usage. This is to make passing a model which will compute additional
    // attributes easier
    modelConstructor?: (data:any) => object

    getCurrentSelection?(selection: Set<any>): void
    refresh?: boolean
}

interface StateI {
    data: Array<any>;
    totalCount: number;
    columns: Array<IDataTableColumn<any>>
    page: number;
    rowsPerPage: number;
}

const APIDatatable: React.FC<PropsI> = (props) => {

    const history = useHistory()

    const {t} = useTranslation()

    const [orderBy, setOrderBy] = useState<{ [key: string]: "asc" | "desc" }>({})

    const [search, setSearch] = useState<{ [key: string]: string }>({})
    const [searchChanged, setSearchChanged] = useState<boolean>(false)

    const [exactSearch, setExactSearch] = useState<{ [key: string]: string }>({})
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<number>(props.rowsPerPage ? props.rowsPerPage : 10)

    // The previous one is needed since changing page or sorting will trigger a selection change and nullify the values. So they have to be reloaded
    const [previousSelected, setPreviousSelected] = useState<Set<string>>(new Set());
    const [selected, setSelected] = useState<Set<string>>(new Set());

    const getAPI = useGetAPI<any>(props.endpoint, calculateRequestParameters({}), [page, rowsPerPage, orderBy, searchChanged, exactSearch])
    const totalCount: number = getAPI.responseHeaders ? parseInt(getAPI.responseHeaders["x-total-count"]) : 0

    let data: any = getAPI.responseBody ? getAPI.responseBody : []
    data.forEach((obj: any) => {
        for (let key in obj) {
            if (typeof data[key] == "object" && data[key] !== null)
                data[key] = JSON.stringify(data[key])
        }
    })

    useEffect(() => {
        setSelected(previousSelected)
    }, [data])


    useEffect(() => {
        if(props.getCurrentSelection)
            props.getCurrentSelection(selected)
    }, [selected])

    useEffect(() => {
        if(props.refresh != undefined)
            getAPI.resend()
    }, [props.refresh])

    data = data.map((entry:any) => {
        // Putting the if inside seems inefficient, but the otherway around didn't work.
        if(props.modelConstructor != null && typeof(props.modelConstructor) === "function")
            {
                // @ts-ignore
                return props.modelConstructor(entry).apiDatatableDisplay(t);
            }
        return entry
    })

    return (
        <>
            {renderResetSelectionButton()}
            {renderSearchForm()}

                <DataTable
                    data={data}
                    pagination={true}
                    paginationServer={true}
                    theme={"dark"}
                    onSort={(column, direction) => changeSortingHandler(column, direction)}
                    sortServer={true}
                    onChangePage={(page) => changePageHandler(page)}
                    onChangeRowsPerPage={(rowsPerPage) => changeRowsPerPageHandler(rowsPerPage)}
                    onRowClicked={(response) => rowClickHandler(response)}
                    paginationTotalRows={totalCount}
                    paginationPerPage={rowsPerPage}
                    customStyles={bracketDarkTheme}
                    noDataComponent={<LoadingScreen />}
                    paginationDefaultPage={page}
                    onSelectedRowsChange={(selectedRowState) => changeSelectedRowsHandler(selectedRowState) }
                    selectableRowSelected={(row) => {
                        if (selected.has(row[props.keyField ? props.keyField : "id"]))
                            return true

                        return false
                    }}
                    {...props}
                />
        </>
    )


    /*
     * Action Handler Functions
     */


    function changePageHandler(page: number) {
        setPage(page)
    }

    function changeRowsPerPageHandler(rowsPerPage: number): void {
        setRowsPerPage(rowsPerPage)
    }

    function changeSortingHandler(column: any, direction: "asc" | "desc") {
        let newValue: any = {}
        newValue[column.selector] = direction
        setOrderBy(newValue)
    }

    function changeSelectedRowsHandler(selectedRowState: SelectedRowStateI): void {
        const keyField: string = props.keyField ? props.keyField : "id"

        setPreviousSelected(new Set(selected))

        const newSelected = new Set(selected)

        // All data which is shown but not selected should not be in the selected Set. So if they would be found in there, they should be removed which happens later
        const toBeDeleted: Set<any> = new Set(data.map((singleElement: any) => {
            return singleElement[keyField]
        }))

        // Add selections and compute which are to be removed
        selectedRowState.selectedRows.forEach((data: any) => {
            newSelected.add(data[keyField])
            toBeDeleted.delete(data[keyField])
        })


        // Removing after having computed the non selected ones of the visible data
        toBeDeleted.forEach((key) => {
            newSelected.delete(key)
        })

        setSelected(new Set(newSelected))

    }

    function rowClickHandler(response:object) {

        const keyField: string = props.keyField ? props.keyField : "id"

        let target = props.onClickTarget ? props.onClickTarget : props.endpoint
        // @ts-ignore
        target = target + "/" + response[keyField]
        history.push(target)
    }

    /*
     * Conditional Rendering
     */

    function renderSearchForm() {
        if(props.searchString)
            return <form>
                <input className={"form-control"} type={"text"} value={search["*"]} placeholder={"Search"} onChange={(event) => {

                    let clone = cloneObject(search)
                    clone["*"] = event.currentTarget.value
                    setSearch(clone)

                    setTimeout(() => {
                        setSearchChanged(!searchChanged)
                    }, 1000)

                }}/>
            </form>
    }

    function renderResetSelectionButton() {
        if(props.selectableRows)
            return (
                <input type={"button"} className={"form-control btn btn-outline-danger"} value={"Reset Selection"} onClick={() => {
                    setSelected(new Set())
                    setPreviousSelected(new Set())
                }}/>
            )
    }


    /*
     * Externalized Computations
     */

    function calculateRequestParameters(parameters: {rowsPerPage?: number, page?: number, orderBy?: object, search?: object, exactSearch?: object}) {
        const insideRowsPerPage: number = parameters.rowsPerPage ? parameters.rowsPerPage : rowsPerPage
        const insidePage: number = parameters.page ? parameters.page : page
        const offset = insideRowsPerPage * (insidePage - 1)
        const limit = insideRowsPerPage

        const insideOrderBy = parameters.orderBy ? parameters.orderBy : orderBy
        const insideSearch = parameters.search ? parameters.search : search
        const insideExactSearch = parameters.exactSearch ? parameters.exactSearch : exactSearch

        return removeKeysWithEmptyObjects({
            offset,
            limit,
            orderBy: removeKeysWithEmptyString(removeKeysWithNullValue(insideOrderBy)),
            search: removeKeysWithEmptyString(removeKeysWithNullValue(insideSearch)),
            exactSearch: removeKeysWithEmptyString(removeKeysWithNullValue(insideExactSearch)),
        })
    }
}


interface SelectedRowStateI {
    allSelected: boolean
    selectedCount: number
    selectedRows: any[]
}

export default APIDatatable