// Refers to the class producing the icon and is bound in later with the rest of the classes.
// Can be extended.
// Does not work great a general type but seems more like one. But it has to be imported.
enum NavigationIcons {
    House = "ion-ios-home-outline",
    Email = "ion-ios-email-outline",
    Photos = "ion-ios-photos-outline",
    Filing = "ion-ios-filing-outline",
    Redo = "ion-ios-redo-outline",
    PieChart = "ion-ios-pie-outline",
    CogWheel = "ion-ios-gear-outline",
    BookMark = "ion-ios-bookmarks-outline",
    Compass = "ion-ios-navigate-outline",
    ColorComposition = "ion-ios-color-filter-outline",
    BriefCase = "ion-ios-briefcase-outline",
    PaperSheets = "ion-ios-paper-outline",
    OpenBook = "ion-ios-book-outline",
    List = "ion-ios-list-outline",
    ShoppingCart = "ion-ios-cart-outline",
    Person = "ion-ios-person-outline",
    Wand = "ion-ios-color-wand-outline",
    Logout = "ion-log-out",
    ExclamationMark = "ion-alert",


}

export default NavigationIcons