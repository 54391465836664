import React, {FunctionComponent} from "react";



const Section: FunctionComponent<{}> = (props) => {
    return <div className={"br-section-wrapper"}>
        {props.children}
    </div>
}

export const SectionHeader: FunctionComponent<{}>  = (props) => {
    return <div className={"br-section-label"}>
        {props.children}
    </div>
}

export const SectionContent: FunctionComponent<{}>  = (props) => {
    return <div className={"br-section-text"}>
        { props.children }
    </div>
}



export default Section