import React from "react"
import User from "../../../models/User";
import {Link} from "react-router-dom"
import useRefreshingGetAPI from "../../../http/hooks/useRefreshingGetAPI";
import { useTranslation } from "react-i18next";

interface PropsI {
    user: User
}

const UserProfileDropDown: React.FC<PropsI> = (props: PropsI) => {


    const {t} = useTranslation("general")

    const {responseBody} = useRefreshingGetAPI<any>("/stats/payment-transactions", 1000*60*10)
    const currentWeek = responseBody ? responseBody[responseBody.length-1] : null
    const earnings = currentWeek ? (parseFloat(currentWeek.amount_sum ) - parseFloat(currentWeek.fixed_fee_sum) - parseFloat(currentWeek.variable_fee_calculated_sum)) : NaN

    return(
        <div className="dropdown">
            <a href="" className="nav-link nav-link-profile" data-toggle="dropdown">
                <span className="logged-name hidden-md-down">{props.user.firstname}</span>
                <img src="https://via.placeholder.com/500" className="wd-32 rounded-circle" alt="" />
                    <span className="square-10 bg-success"></span>
            </a>
            <div className="dropdown-menu dropdown-menu-header wd-250">
                <div className="tx-center">
                    <a href=""><img src="https://via.placeholder.com/500" className="wd-80 rounded-circle" alt="" /></a>
                    <h6 className="logged-fullname">{props.user.fullName}</h6>
                    <p>{props.user.email}</p>
                </div>
                <hr />
                <div className="tx-center">
                    <span className="profile-earning-label">{t("layout_element.earnings")}</span>
                    <h3 className="profile-earning-amount">{earnings} €</h3>
                </div>
                <hr />
                    <ul className="list-unstyled user-profile-nav">
                        <li><Link to="/users/security"><i className="icon ion-ios-locked-outline"></i> {t("layout_element.security")}</Link></li>
                        <li><Link to="/users/account-information"><i className="icon ion-ios-cog-outline"/> {t("layout_element.settings")}</Link></li>
                        <li><Link to="/logout"><i className="icon ion-power"></i> {t("layout_element.sign_out")}</Link></li>
                    </ul>
            </div>
        </div>
    )

}

export default UserProfileDropDown