import {IDataTableColumn} from "react-data-table-component";
import {TFunction} from "i18next";
import dateFromIso8601ToLocal from "../utils/time/dateFromIso8601ToLocal";

class User {

    public id?: number
    public firstname?: string
    public lastname?: string
    public state?: string
    public email?: string
    public email_verified_at?: string
    public is_admin?: boolean
    public created_at?: string
    public updated_at?: string

    get fullName(): string {
        return this.firstname + " " + this.lastname
    }

    constructor(init: Partial<User>) {
        Object.assign(this, init)
    }

    genericDisplay(): string {
        return "User: " + this.fullName
    }

    apiDatatableDisplay(t: TFunction): any {
        return {
            id: this.id,
            lastname: this.lastname,
            firstname: this.firstname,
            // Same as user state
            state: this.state ? t('shop_state:' + this.state) : null,
            created_at: dateFromIso8601ToLocal(this.created_at),
            updated_at: dateFromIso8601ToLocal(this.updated_at)
        }
    }
}

export const standardColumns: Array<IDataTableColumn<any>> = [
    {
        name: "",
        selector: "id",
        sortable: true,
    },{
        name: "",
        selector: "firstname",
        sortable: true,
    },{
        name: "",
        selector: "lastname",
        sortable: true,
    }, {
        name: "",
        selector: "created_at",
        sortable: true
    }, {
        name: "",
        selector: "updated_at",
        sortable: true
    }
]

export const singleResourceDisplay: Array<SingleResourceDisplayStructure> = [
    {
        identifier: "firstname",
        editable: "text",
    },{
        identifier: "lastname",
        editable: "text",
    },{
        identifier: "email",
        editable: "text",
    },{
        identifier: "email_verified_at",
        valueTransform: "DateAndTime"
    }
]

export default User