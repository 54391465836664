import React, {ReactElement} from "react"
import useGetAPI from "../../http/hooks/useGetAPI";
import APIBuffering from "../common/APIBuffering";
import performDeleteRequest from "../../http/functions/performDeleteRequest";
import performPostRequest from "../../http/functions/performPostRequest";

const TwoFASetup: React.FC<{}> = () => {
    const getAPI = useGetAPI<{ qr_code: string }>("/users/current/2fa-secret")
    let image = getAPI.responseBody ? getAPI.responseBody.qr_code : ""


    if(!getAPI.loading && image == "")
        return (
            <input className={"btn btn-success"} type={"button"} onClick={ async () => {
                await performPostRequest("/users/current/2fa-secret", {})
                getAPI.resend()
            }} value={"Activate 2FA"}/>
        )

    return(
        <APIBuffering {...getAPI}>
            <img src={image} />

            <br />
            <br />

            <input className={"btn btn-danger"} type={"button"} onClick={ async () => {
                await performDeleteRequest("/users/current/2fa-secret", {})
                getAPI.resend()
            }} value={"Deactivate 2FA"} />
        </APIBuffering>
    )

}

export default TwoFASetup