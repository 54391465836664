import React from "react"
import DisplayInformationList from "../../common/Content/DisplayInformationList";
import {useParams} from "react-router-dom"
import useGetAPI from "../../../http/hooks/useGetAPI";
import ContentEmbedding from "../../common/Content/ContentEmbedding";
import Payout, {singleResourceDisplay} from "../../../models/Payout";
import SubListResourceEmbedding from "../subresources/SubListResourceEmbedding";
import PaymentTransactionList from "../subresources/PaymentTransactionList";
import {standardColumns} from "../../../models/PaymentTransaction";
import Section, {SectionContent} from "../../common/Content/Section";
import DownloadButton from "../../common/DownloadButton";

const PayoutSingle: React.FC<{}> = () => {
    const { id } = useParams()

    const GetAPI = useGetAPI<Payout>("/payouts/" + id)
    let payout = GetAPI.responseBody ? new Payout(GetAPI.responseBody) : new Payout({})

    return (
        <ContentEmbedding title={"Payout Overview"} {...GetAPI} >
            <Section>
                <SectionContent>
                    <DisplayInformationList
                        structure={singleResourceDisplay}
                        instance={payout}
                        translationPath={"payout"}
                    />
                    <br />
                    <div style={{textAlign: "center"}}>
                        <DownloadButton endpoint={"/payouts/"+ payout.id +"/pdf"} filename={"invoice.pdf"} />
                    </div>
                </SectionContent>
            </Section>

            <br />

            <SubListResourceEmbedding
                title={"Payouts"}
                subResource={PaymentTransactionList}
                shopId={payout.shop_id ? payout.shop_id : -1}
                keyPrefix={"table_header:payment_transactions"}
                standardColumns={standardColumns}
                permissionRequired={"may_view_transaction_history"}
                payoutId={payout.id ? payout.id : -1}
            />
        </ContentEmbedding>
    )
}

export default PayoutSingle