import React, {FormEvent} from "react"
import APISelect from "../APISelect";

interface PropsI {
    onChange(event: FormEvent<HTMLSelectElement>): void
    className?: string
}

const ConflictTypeSelect: React.FC<PropsI> = (props: PropsI) => {
    return (
        <APISelect className={props.className} endpoint={"/conflict-types"} valueKey={"name"} displayKey={"name"} onChange={(event) => props.onChange(event)} />
    )
}

export default ConflictTypeSelect