import React, {ReactElement, useEffect, useState} from "react"
import useURLQuery from "../../utils/routes/useURLQuery";
import axios from "../../http/axios/apiv1";
import TicketMessage from "../../models/TicketMessage";
import Conflict from "../../models/Conflict";
import Payout from "../../models/Payout";
import PaymentTransaction from "../../models/PaymentTransaction";
import Shop from "../../models/Shop";
import Ticket from "../../models/Ticket";
import LoadingScreen from "../common/Filler/LoadingScreen";
import ContentEmbedding from "../common/Content/ContentEmbedding";
import Section, {SectionContent} from "../common/Content/Section";
import { useHistory } from "react-router-dom";


const GlobalSearch: React.FC = () => {

    const history = useHistory()

    const [results, setResults] = useState<any>(null)

    const searchEndpoints: Array<{ endpoint: string, model: any} > = [
        {endpoint: "/tickets", model: Ticket},
        {endpoint: "/ticket-messages", model: TicketMessage},
        {endpoint: "/conflicts", model: Conflict},
        {endpoint: "/payouts", model: Payout},
        {endpoint: "/payment-transactions", model: PaymentTransaction},
        {endpoint:"/shops", model: Shop},
    ]

    const query = useURLQuery().get("q")

    useEffect(() => {
        async function fetchData() {
            const newResult = await Promise.all(searchEndpoints.map(async ({endpoint, model}) => {
                return await axios.get<any[]>(endpoint + "?offset=0&limit=10&search%5B%2A%5D=" + query).then(({data}) => {

                    return data.map((modelData: any) => {
                        return new model(modelData)
                    })

                }).catch(() => {
                    return null
                })

            })).then((value) => {
                return value
            } )


            setResults(newResult)
        }

        fetchData()

    }, [query] )

    return (
        <ContentEmbedding title={"Global Search"}>
            <Section>
                <SectionContent>
                    <div className={"list-group"}>
                        {renderResults()}
                    </div>
                </SectionContent>
            </Section>
    </ContentEmbedding>)


    function renderResults(): ReactElement[] {

        try {
            const resultOfFunction: ReactElement[] = []
            results.forEach((singleResult: any) => {
                singleResult.forEach((element: any) => {
                    try {
                        if (element)
                            resultOfFunction.push(
                                <div className={"list-group-item pd-y-20 rounded-top-0"} onClick={() => {
                                    if(element.moreInformationPage)
                                        history.push(element.moreInformationPage)
                                }}>
                                    <div className={"media"}>
                                        <div className={"media-body"}>
                                            {element.genericDisplay()}
                                        </div>
                                    </div>
                                </div>
                            )
                    } catch {}
                })

            })

            return resultOfFunction;
        }
        catch {
            return [<LoadingScreen />]
        }
    }

}


export default GlobalSearch