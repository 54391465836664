import React, {useContext} from "react"
import Shop, {singleResourceDisplay} from "../../../models/Shop";
import { useParams } from "react-router-dom"
import ContentEmbedding from "../../common/Content/ContentEmbedding";
import Section, {SectionContent, SectionHeader} from "../../common/Content/Section";
import { useTranslation } from "react-i18next"
import DisplayInformationList from "../../common/Content/DisplayInformationList";
import PermissionContext from "../../../contexts/PermissionContext";
import Permission from "../../../models/Permission";
import {standardColumns as credentialsStandardColumns} from "../../../models/PaymentMethodCredential";
import {standardColumns as payoutsStandardColumns} from "../../../models/Payout"
import PaymentMethodCredentialList from "../subresources/PaymentMethodCredentialList";
import SubListResourceEmbedding from "../subresources/SubListResourceEmbedding";
import useGetAPI from "../../../http/hooks/useGetAPI";
import getPermissionFromMap from "../../../utils/auth/getPermissionFromMap";
import PayoutList from "../subresources/PayoutList";
import AddPaymentMethodToShopForm from "../Form/AddPaymentMethodToShopForm";
import fileToBase64 from "../../../utils/files/fileToBase64";
import performPutRequest from "../../../http/functions/performPutRequest";
import ShopSettingLog from "../subresources/ShopSettingLog";
import AddVerificationFileForm from "../Form/AddVerificationFileForm";
import DisplayExistingVerificationFiles from "../subresources/DisplayExistingVerificationFiles";
import LoadingScreen from "../../common/Filler/LoadingScreen";

const ShopSingle: React.FC<{}> = () => {

    const { id } = useParams()

    const {t} = useTranslation("static_page_content")

    const getAPI = useGetAPI<Shop>("/shops/" + id)
    let shop = getAPI.responseBody

    if(!shop)
        return <LoadingScreen />

    if(shop.state !== "ACTIVE")
        return (
            <ContentEmbedding>
                <Section>
                    <SectionHeader>{t("verification_page.section_header")}</SectionHeader>
                    <SectionContent>
                        {t("verification_page.please_add_verification")}
                        <br />
                        {t("verification_page.needed_documents")}

                        <br />
                        <br />

                        <AddVerificationFileForm shopId={shop.id}/>

                        <br />
                        <br />

                        <DisplayExistingVerificationFiles shopId={shop.id} />
                    </SectionContent>
                </Section>
            </ContentEmbedding>
        )

    return (
        <ContentEmbedding title={shop.title} subtitle={shop.description} {...getAPI} >
            <DisplayComponent shop={shop} resend={getAPI.resend}/>
        </ContentEmbedding>
        )




}


const DisplayComponent: React.FC<{shop: Partial<Shop>, resend(): void}> = (props) => {

    const { t } = useTranslation()

    const permissionMap: Map<number, Permission> | null = useContext(PermissionContext)



    return (
        <React.Fragment>
            <Section>
                <SectionHeader>{t("overview")}</SectionHeader>
                <SectionContent>
                    <div className={"row"}>
                        <div className={"col-md-9"}>
                            <DisplayInformationList
                                structure={singleResourceDisplay}
                                instance={props.shop}
                                translationPath={"shop"}
                                editable={Boolean(getPermissionFromMap(permissionMap, props.shop.id!, "may_change_shop_settings"))}
                                putEndPoint={"/shops/" + props.shop.id!.toString()}
                            />
                        </div>
                        <div className={"col-md-3"}>
                            {props.shop.logo ? <img src={props.shop!.logo}  alt={"company logo"}/> : "No logo"}
                            <br />
                            <br />
                            <input type={"file"} onChange={ async (event) => {
                                const file = event.target.files![0]
                                const base64File = await fileToBase64(file)
                                const success = await performPutRequest("/shops/" + props.shop.id, {logo: base64File})
                                if(success)
                                    props.resend()
                            }}/>
                        </div>
                    </div>
                </SectionContent>
            </Section>

            <br/>

            <SubListResourceEmbedding
                title={"Payment Method Credentials"}
                subResource={PaymentMethodCredentialList}
                shopId={props.shop.id}
                keyPrefix={"table_header:payment_method_credentials"}
                standardColumns={credentialsStandardColumns}
                permissionRequired={"may_view_payment_method_credentials"}
             />

             <br />

            {renderAddPaymentMethod()}

            <br />

            <SubListResourceEmbedding
                title={"Payouts"}
                subResource={PayoutList}
                shopId={props.shop.id}
                keyPrefix={"table_header:payouts"}
                standardColumns={payoutsStandardColumns}
                permissionRequired={"may_view_payout_history"}
            />

            <br />


            {props.shop.id ? <ShopSettingLog shopId={props.shop.id}/>: null}

        </React.Fragment>
    )

    function renderAddPaymentMethod() {
        if(getPermissionFromMap(permissionMap, props.shop.id!, "may_change_payment_method_credentials"))
            return <Section>
                     <SectionHeader>Add a Payment Method</SectionHeader>
                     <SectionContent>
                            <AddPaymentMethodToShopForm shopId={props.shop.id!}/>
                     </SectionContent>
             </Section>

        return null
    }

}

export default ShopSingle