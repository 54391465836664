import {IDataTableColumn} from "react-data-table-component";
import {TFunction} from "i18next";
import dateFromIso8601ToLocal from "../utils/time/dateFromIso8601ToLocal";

class Login {

    public id?: number
    public ip_address?: string
    public user_agent?: string
    public created_at?: string
    public success_type?: string

    constructor(init: Partial<Login>) {
        Object.assign(this, init)
    }

    apiDatatableDisplay(t: TFunction): any {

        return {
            id: this.id,
            ip_address: this.ip_address,
            user_agent: this.user_agent,
            created_at: dateFromIso8601ToLocal(this.created_at),
            success_type: t("model_attributes:login.success_type." + this.success_type),
        }
    }
}

export const standardColumns: Array<IDataTableColumn<any>> = [
    {
        name: "",
        "selector": "id",
    }, {
        name: "",
        selector: "ip_address",
    }, {
        name: "",
        selector: "user_agent",
    }, {
        name: "",
        selector: "created_at",
    }, {
        name: "",
        selector: "success_type",
    }
]

export default Login