import React from "react"
import {PieChart, Pie, Tooltip, LabelList} from "recharts"
import useGetAPI from "../../http/hooks/useGetAPI";
import APIBuffering from "../common/APIBuffering";
import colors from "./colors";

interface StatsI {
    country_code: string,
    count: number
}

interface StatsTransformedI {
    name: string,
    value: number,
    fill?: string,
}

interface PropsI {
}

const CountryPieChart: React.FC<PropsI> = (props: PropsI) => {

    const getAPI = useGetAPI<StatsI[]>("/stats/countries");

    let data: StatsTransformedI[]  = []
    if (getAPI.responseBody) {
        getAPI.responseBody.forEach((element, index) => {
            data.push({
                name: element.country_code,
                value: element.count,
                fill: colors[index % colors.length]
            })
        })
    }

    return(
        <APIBuffering {...getAPI}>
            <PieChart width={700} height={500}>
                <Pie dataKey="value" isAnimationActive={false} data={data} cx={400} cy={300} outerRadius={180}>
                    <LabelList dataKey={"name"} position={"outside"}/>
                </Pie>
                <Tooltip isAnimationActive={false} payload={data}/>
            </PieChart>
        </APIBuffering>
    )
}

export default CountryPieChart