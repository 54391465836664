import React, {useState} from "react"
import ConflictTypeSelect from "../../common/FormElement/FullSelects/ConflictTypeSelect";
import performPutRequest from "../../../http/functions/performPutRequest";
import performPostRequest from "../../../http/functions/performPostRequest";

interface PropsI {
    paymentTransactionUUID: string
    onResponse?(success: boolean): void
}


const AddConflictForm: React.FC<PropsI> = (props: PropsI) => {

    const [description, setDescription] = useState<string>("")
    const [conflictType, setConflictType] = useState<string>("")

    return (
        <form>
            <input className={"form-control"} type={"text"} placeholder={"Description"} value={description} onChange={(event) => setDescription(event.currentTarget.value)}/>
            <ConflictTypeSelect className={"form-control"} onChange={(event) => setConflictType(event.currentTarget.value)} />

            <br />
            <div style={{textAlign: "center"}}>
                <input disabled={(description === "" || conflictType === "")} className={"center btn btn-outline-success"} type={"button"} value={"Add Conflict"} onClick={ async () => {
                    const {success} = await performPostRequest("/conflicts", {
                        conflict_type_name: conflictType,
                        description: description,
                        transaction_uuid: props.paymentTransactionUUID,
                    })

                    if(props.onResponse)
                        props.onResponse(success)


                    if(success) {
                        setDescription("")
                        setConflictType("")
                    }

                }}/>
            </div>
        </form>
    )
}

export default AddConflictForm