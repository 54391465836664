import {IDataTableColumn} from "react-data-table-component";
import {TFunction} from "i18next";
import dateFromIso8601ToLocal from "../utils/time/dateFromIso8601ToLocal";

class Payout {
    public id?: number
    public shop_id?: number
    public used_period?: string
    public date?: string
    public paid_out_at?: string
    public note?: string
    public total_sum?: number
    public total_fixed_fee?: number
    public total_variable_fee?: number
    public total_sum_after_fees?: number
    public transaction_count?: number
    public created_at?: string
    public updated_at?: string

    constructor(init: Partial<Payout>) {
        Object.assign(this, init)

    }

    apiDatatableDisplay(t: TFunction): any {
        let total_sum_after_fees = null
        if(this.total_sum != null && this.total_variable_fee != null && this.total_fixed_fee != null)
            total_sum_after_fees = this.total_sum - this.total_fixed_fee - this.total_variable_fee


        return {
            id: this.id,
            used_period: this.used_period,
            date: dateFromIso8601ToLocal(this.date),
            paid_out_at: this.paid_out_at ? dateFromIso8601ToLocal(this.paid_out_at) : null,
            total_sum: this.total_sum,
            total_fixed_fee: this.total_fixed_fee,
            total_variable_fee: this.total_variable_fee,
            total_sum_after_fees,
            transaction_count: this.transaction_count,
            // Only in accounting
            note: this.note
        }
    }

    genericDisplay(): string {
        return "Payout: " + this.id
    }
}

export const standardColumns: Array<IDataTableColumn<any>> = [
    {
        name: "",
        selector: "id",
        sortable: true,
    },{
        name: "",
        selector: "used_period",
        sortable: true,
    },{
        name: "",
        selector: "date",
        sortable: true,
    }, {
        name: "",
        selector: "paid_out_at",
        sortable: true
    }, {
        name: "",
        selector: "total_sum",
        sortable: false
    }, {
        name: "",
        selector: "total_fixed_fee",
        sortable: false
    }, {
        name: "",
        selector: "total_variable_fee",
        sortable: false
    }, {
        name: "",
        selector: "total_sum_after_fees",
        sortable: false
    }, {
        name: "",
        selector: "transaction_count",
        sortable: false,
    }]


export const singleResourceDisplay: Array<SingleResourceDisplayStructure> = [
    {
        identifier: "used_period",
    },{
        identifier: "date",
        valueTransform: "DateAndTime",
    },{
        identifier: "total_sum"
    },{
        identifier: "total_fixed_fee",
    },{
        identifier: "total_variable_fee",
    }, {
        identifier: "total_sum_after_fees"
    }
]

export default Payout