import React, {useState} from "react";
import performPostRequest from "../../../http/functions/performPostRequest";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next"


const CreateShopForm: React.FC<{}> = () => {
    // One could debate using one state containing the whole model. But it seems easier to keep it seperate than to write more complex replacement logic with immer
    // or using a reducer
    const [title, setTitle] = useState<string>("")
    const [description, setDescription] = useState<string>("")
    const [notificationEmail, setNotificationEmail] = useState<string>("")
    const [successUrl, setSuccessUrl] = useState<string>("")
    const [notifyUrl, setNotifyUrl] = useState<string>("")
    const [cancelUrl, setCancelUrl] = useState<string>("")
    const [notifyViaEmail, setNotifyViaEmail] = useState<boolean>(true)
    const [website, setWebsite] = useState<string>("")

    const history = useHistory()
    const {t} = useTranslation()


    return <form>
        <input type={"text"} className={"form-control"} placeholder={t("general:form_fields.shop_title")} value={title} onChange={(event) => {
            setTitle(event.currentTarget.value)
        }}/>
        <br />
        <textarea className={"form-control"} placeholder={t("general:form_fields.description")} value={description} onChange={(event) => {
            setDescription(event.currentTarget.value)
        }}/>
        <br />
        <input type={"text"} className={"form-control"} placeholder={t("general:form_fields.notification_email")} value={notificationEmail} onChange={(event) => {
            setNotificationEmail(event.currentTarget.value)
        }}/>
        <br />
        <input type={"text"} className={"form-control"} placeholder={t("general:form_fields.success_url")} value={successUrl} onChange={(event) => {
            setSuccessUrl(event.currentTarget.value)
        }}/>
        <br />
        <input type={"text"} className={"form-control"} placeholder={t("general:form_fields.notify_url")} value={notifyUrl} onChange={(event) => {
            setNotifyUrl(event.currentTarget.value)
        }}/>
        <br />
        <input type={"text"} className={"form-control"} placeholder={t("general:form_fields.cancel_url")} value={cancelUrl} onChange={(event) => {
            setCancelUrl(event.currentTarget.value)
        }}/>
        <br />
        {t("general:form_fields.notify_via_email")}
            <input type={"radio"} name={"notify"} onChange={(event) => {
                event.persist()
                setNotifyViaEmail(true)
            }}
            checked={notifyViaEmail}
            /> {t("boolean:YesNo.true")}

            <input type={"radio"} name={"notify"} onChange={(event) => {
                event.persist()
                setNotifyViaEmail(false)
            }}
            checked={!notifyViaEmail}
            /> {t("boolean:YesNo.false")}

        <br />

        <div style={{textAlign: "center"}}>
            <input type={"button"} className={"btn btn-success"} value={t("general:form_actions.create_shop").toString()} disabled={
                title === "" || successUrl === "" || notifyUrl === "" || cancelUrl === ""
            }
            onClick={async () => {
                const {success, data} = await performPostRequest("/shops", {
                    title,
                    description: description !== "" ? description : null,
                    merchant_notification_email: notificationEmail !== "" ? notificationEmail : null,
                    success_url: successUrl,
                    notify_url: notifyUrl,
                    cancel_url: cancelUrl,
                    website: website !== "" ? website : null,
                    notify_via_email: notifyViaEmail,
                })

                if(success) {
                    history.push("/shops/" + data.id.toString() )
                }
            }}
        />
        </div>
    </form>
}


export default CreateShopForm