import React from "react";
import {Route} from "react-router-dom";
import Error404 from "../components/common/Error/Error404";

interface PropsI {
    routeInformationen?: Array<RouteInformation>
}

const UniversalRoutes = (props: PropsI) => {
    // Order matters. Keep 404 last
    return (
        <React.Fragment>
            <Route component={Error404}/>
        </React.Fragment>
    )
}

export default UniversalRoutes