import React from "react";
import APIDatatable from "../../common/APIDatatable/APIDatatable";
import SubResourceInterface from "./SubResourceInterface";
import Payout from "../../../models/Payout"


const PayoutList: React.FC<SubResourceInterface> = (props) => {
    return(
        <APIDatatable
            endpoint={"/payouts?shop_id="+ props.shopId}
            columns={props.standardColumns}
            rowsPerPage={10}
            pagination={true}
            onClickTarget={"/payouts"}
            modelConstructor={(data: any ) => {
                return new Payout(data)
            }}
            searchString
        />
    )
}

export default PayoutList