import React, {useState} from "react"
import APIDatatable from "../common/APIDatatable/APIDatatable";
import User, {standardColumns as userStandardColumns} from "../../models/User";
import ContentEmbedding from "../common/Content/ContentEmbedding";
import Section, {SectionContent, SectionHeader} from "../common/Content/Section";
import {standardColumns as shopStandardColumns} from "../../models/Shop";
import PaymentMethodCredential, {approvalColumns, standardColumns} from "../../models/PaymentMethodCredential";
import ApprovePaymentMethodCredentialForm from "../resources/Form/ApprovePaymentMethodCredentialForm";
import { useTranslation } from "react-i18next";
import translateListOfObjects from "../../utils/translation/translateListOfObjects";

const Review: React.FC<{}> = () => {

    const [paymentMethodCredentialId, setPaymentMethodCredentialId] = useState<number | null>(null)
    const [refreshPaymentMethodCredentials, setRefreshPaymentMethodCredentials] = useState<() => void>(() => {})
    const [refreshToggle, setRefreshToggle] = useState<boolean>(false)

    const {t} = useTranslation()

    const processedShopColumns = shopStandardColumns.filter((element) => {
        return element.selector !== "state" && element.selector !== "in_testing_mode"
    })

    translateListOfObjects(processedShopColumns, "selector", "name", t, "table_header:shops.")
    translateListOfObjects(approvalColumns, "selector", "name", t, "table_header:payment_method_credentials.")
    translateListOfObjects(userStandardColumns, "selector", "name", t, "table_header:users.")

    return(
        <ContentEmbedding>
            <Section>
                <SectionHeader>Users Awaiting Review</SectionHeader>
                <SectionContent>
                    <APIDatatable
                        endpoint={"/users?exactSearch[state]=PENDING"}
                        onClickTarget={"/approve-user"}
                        columns={userStandardColumns}
                        modelConstructor={(init) => new User(init)}
                    />
                </SectionContent>
            </Section>

            <br />

            <Section>
                <SectionHeader>Shops Awaiting Review</SectionHeader>
                <SectionContent>
                    <APIDatatable
                        onClickTarget={"/approve-shop"}
                        endpoint={"/shops?exactSearch[state]=PENDING"}
                        columns={processedShopColumns}
                    />
                </SectionContent>
            </Section>

            <br />

            <Section>
                <SectionHeader>Payment Methods Awaiting Review</SectionHeader>
                <SectionContent>
                    <APIDatatable
                        endpoint={"/payment-method-credentials?exactSearch[is_activated]=0"}
                        columns={approvalColumns}
                        onRowClicked={(row) => {
                            setPaymentMethodCredentialId(row.id)
                        }}
                        modelConstructor={(init) => new PaymentMethodCredential(init)}
                        refresh={refreshToggle}
                    />

                    <br />

                    <ApprovePaymentMethodCredentialForm paymentMethodCredentialId={paymentMethodCredentialId} onSuccess={() => {
                        setPaymentMethodCredentialId(null)
                        setRefreshToggle(!refreshToggle)
                    }}/>
                </SectionContent>
            </Section>
        </ContentEmbedding>
    )
}

export default Review