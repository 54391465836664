import React from "react"
import ShopList from "../resources/List/ShopList";
import ContentEmbedding from "../common/Content/ContentEmbedding";
import PaymentTransactionLineChart from "../graphs/PaymentTransactionLineChart";
import DownloadButton from "../common/DownloadButton";

const Root: React.FC<{}> = () => {

    return(
        <React.Fragment>
            <ContentEmbedding>
                <PaymentTransactionLineChart />
                <ShopList />
            </ContentEmbedding>
        </React.Fragment>
    )
}

export default Root