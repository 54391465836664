import React from "react";
import { Switch, Route } from "react-router-dom";
import ShopSingle from "../components/resources/Single/ShopSingle";
import userLinks from "../components/common/Navigation/UserLinks";
import loadRouteFromMenuLinkDescription from "../utils/routes/loadRouteFromMenuLinkDescription";
import UniversalRoutes from "./UniversalRoutes";
import PaymentMethodCredentialSingle from "../components/resources/Single/PaymentMethodCredentialSingle";
import PayoutSingle from "../components/resources/Single/PayoutSingle";
import TicketSingle from "../components/resources/Single/TicketSingle";
import AuthLogout from "../components/auth/AuthLogout";
import Security from "../components/pages/Security";
import AccountInformation from "../components/pages/Users/AccountInformation";
import GlobalSearch from "../components/pages/GlobalSearch";



const UserRoutes = () => {


    return (
        <Switch>
            { loadRouteFromMenuLinkDescription(userLinks)}
            <Route exact path={"/shops/:id"} component={ShopSingle} />
            <Route exact path={"/payment-method-credentials/:id"} component={PaymentMethodCredentialSingle} />
            <Route exact path={"/payouts/:id"} component={PayoutSingle} />
            <Route exact path={"/tickets/:id"} component={TicketSingle} />
            <Route exact path={"/users/security"} component={Security} />
            <Route exact path={"/users/account-information"} component={AccountInformation} />
            <Route exact path={"/logout"} component={AuthLogout} />
            <Route exact path={"/global-search"} component={GlobalSearch} />
            <UniversalRoutes />
        </Switch>
    )
}

export default UserRoutes;