import {useEffect, useState} from "react"
import axios from "../axios/apiv1";

interface ReturnI<T> {
    loading: boolean
    failed: boolean
    responseBody: T | null
    responseHeaders: any
    resend(): void
}


// This Hook will be similar to the useGetAPI. The only difference should be, that it keeps fetching data continiously for a defined interval

const useRefreshingGetAPI = <T>(endpoint: string, interval: number = 1000*60*5, dependencies: Array<any> = []): ReturnI<T> => {

    const [loading, setLoading] = useState<boolean>(false)
    const [failed, setFailed] = useState<boolean>(false)
    const [responseBody, setResponseBody] = useState<T | null>(null)
    const [responseHeaders, setResponseHeaders] = useState<any>(null)


    const retrievingFunction = () => {
        setLoading(true)
        axios.get<T>(endpoint).then(({data, headers}) => {
            setFailed(false)
            setResponseBody(data)
            setResponseHeaders(headers)
        }).catch((error) => {
            console.log(error)
            setFailed(true)
        }).then(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        // Calling the retrieval Function once now and then in the interval
        const intervalFunction = setInterval(retrievingFunction, interval)

        retrievingFunction()
        return () => clearInterval(intervalFunction)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endpoint, ...dependencies])

    return {loading, failed, responseBody, responseHeaders, resend: retrievingFunction}
}

export default useRefreshingGetAPI