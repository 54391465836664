import {IDataTableColumn} from "react-data-table-component";
import React from "react";
import {TFunction} from "i18next";
import dateFromIso8601ToLocal from "../utils/time/dateFromIso8601ToLocal";

class Conflict {

    public id?: number
    public transaction_uuid?: string
    public conflict_type_name?: string
    public description?: string
    public conflict_solution_name?: string
    public merchant_note?: string
    public created_at?: string
    public updated_at?: string

    constructor(init: Partial<Conflict>) {
        Object.assign(this, init)
    }

    genericDisplay() {
        return (
            <>
                <h6 className={"tx-white"}>Ticket Message</h6>
                {this.description}
            </>
        )
    }

    apiDatatableDisplay(t: TFunction): any {

        const translateYesNo = (value: boolean | undefined): string | null => {
            return value !== undefined ? t("boolean:YesNo." + Boolean(value).toString()) : null
        }

        return {
            id: this.id,
            transaction_uuid: this.transaction_uuid,
            conflict_type_name: this.conflict_type_name ? t('conflict_type_and_solution:conflict_type.' + this.conflict_type_name) : null,
            description: this.description,
            conflict_solution_name: this.conflict_solution_name ? t('conflict_type_and_solution:conflict_solution.' + this.conflict_solution_name) : null,
            merchant_note: this.merchant_note,
            created_at: dateFromIso8601ToLocal(this.created_at),
            updated_at: dateFromIso8601ToLocal(this.updated_at),
        }
    }

    get moreInformationPage(): string {
        return "/conflicts"
    }
}

export const standardColumns: Array<IDataTableColumn<any>> = [
    {
        name: "",
        selector: "id",
        sortable: true,
    }, {
        name: "",
        selector: "transaction_uuid",
        sortable: false,
    }, {
        name: "",
        selector: "conflict_type_name",
        sortable: true
    }, {
        name: "",
        selector: "description",
        sortable: false,
        wrap: true,
    }, {
        name: "",
        selector: "conflict_solution_name",
        sortable: true
    }, {
        name: "",
        selector: "merchant_note",
    },{
        name: "",
        selector: "created_at",
        sortable: true
    }, {
        name: "",
        selector: "updated_at",
        sortable: true
    }
]

export default Conflict