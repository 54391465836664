import React from "react"
import useGetAPI from "../../../http/hooks/useGetAPI";
import APIBuffering from "../../common/APIBuffering";
import DownloadButtonViaBlob from "../../common/DownloadButtonViaBlob";

interface PropsI {
    // Specify exactly one of the following two
    shopId?: number
    userId?: number
}

const DisplayExistingVerificationFiles: React.FC<PropsI> = (props: PropsI) => {
    let endpoint: string = ""

    if (props.shopId)
        endpoint = "/shops/" + props.shopId.toString()
    else if(props.userId)
        endpoint = "/users/" + props.userId.toString()

    const getAPI = useGetAPI<any[]>(endpoint + "/verification-files")

    return(
        <APIBuffering {...getAPI}>
            {getAPI.responseBody ?
                getAPI.responseBody.map((file) => {
                    return (
                        <div>
                            {file.file_name}
                            <DownloadButtonViaBlob file={file.file} filename={file.file_name} />
                        </div>
                    )
                })

                : null
            }
        </APIBuffering>
        )
}


export default DisplayExistingVerificationFiles