import {useEffect, useState} from "react"
import axios from "../axios/apiv1";

const qs = require("qs")

interface ReturnI<T> {
    loading: boolean
    failed: boolean
    responseBody: T | null
    responseHeaders: any

    // To trigger manual rerendering
    resend: (functionParameters?: object | null) => void
}

const useGetAPI = <T>(endpoint: string, parameters: object = {}, dependencies: Array<any> = []): ReturnI<T> => {

    const [loading, setLoading] = useState<boolean>(false)
    const [failed, setFailed] = useState<boolean>(false)
    const [responseBody, setResponseBody] = useState<T | null>(null)
    const [responseHeaders, setResponseHeaders] = useState<any>(null)


    useEffect(() => {
        setLoading(true)
        resend(parameters)


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endpoint, ...dependencies])

    // Externalized so it can be exported.
    // The name is confusing in this context but it just starts a getRequest and processes it accordingly.
    // The name should describe the API of the hook better and makes more sense there.
    async function resend(functionParameters: object | null = null) {
        let usedParameters = parameters
        if(functionParameters)
            usedParameters = functionParameters

        // It is possible to send a get request body but not recommended so we wrap it to make it parameters immediately.
        axios.get<T>(endpoint, {params: usedParameters, paramsSerializer: (params) => {

            return qs.stringify(params, {arrayFormat: 'brackets'}) }}).then(({data, headers}) => {
                setFailed(false)
                setResponseBody(data)
                setResponseHeaders(headers)
            }).catch((error) => {
                console.log(error)
                setResponseBody(null)
                setResponseHeaders(null)
                setFailed(true)
            }).then(() => {
                setLoading(false)
            })
    }

    return {loading, failed, responseBody, responseHeaders, resend}
}

export default useGetAPI