import React, {ReactElement, useState} from "react"
import {enterTwoFA, login} from "../../utils/auth/authController"
import {Link} from "react-router-dom";


const AuthLogin: React.FC<{}> = () => {

    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")

    const [twoFAStage, setTwoFAStage] = useState<boolean>(false)
    const [secret, setSecret] = useState<string>("")

    return (
        <div className={"login-wrapper wd-300 wd-xs-350 pd-25 pd-xs-40 bg-br-primary rounded bd bd-white-1"}>
            <div className={"signin-logo tx-center tx-28 tx-bold tx-white"}>Alma2</div>

            {/* The subtitle is left empty here for the spacing it introduces. A subtitle can go in between the divs*/}
            <div className={"tx-center mg-b-60"}></div>

            {renderLoginForm()}

        </div>
    );

    async function onClickHandler(): Promise<void> {
        const {success, twoFANeeded} = await login(email, password)
        if (success) {
            if(!twoFANeeded)
                window.location.href = "/"

            setTwoFAStage(twoFANeeded)
        } else {
            // TODO: display error message logic
        }
    }

    async function onClickTwoFAHandler(): Promise<void> {
        const success = await enterTwoFA(secret)
        if (success) {
            window.location.href = "/"

        } else {
            // TODO: display error message logic
        }
    }

    function renderLoginForm(): ReactElement {
        if(!twoFAStage)
            return (
                <React.Fragment>
                    <div className={"form-group"}>
                        <input className={"form-control form-control-dark"} type="text" onChange={(event) => setEmail(event.currentTarget.value)} value={email} placeholder={"Enter your Email"}/>
                    </div>

                    <div className={"form-group"}>
                        <input className={"form-control form-control-dark"} type="password"  onChange={(event) => setPassword(event.currentTarget.value)} value={password} placeholder={"Enter your password"}/>
                    </div>
                    {/* <Link className="tx-info tx-12 d-block mg-t-10" to="password-reset">Forgot password?</Link> */}

                    <button className={"btn btn-info btn-block"} onClick={() => onClickHandler()}>Login</button>

                    <div className={"mg-t-60 tx-center"}>
                        Not yet a member? <Link className={"tx-info"} to={"register"}>Register</Link>
                    </div>
                </React.Fragment>
            )

        return (
            <div className={"form-group"}>
                <input className={"form-control form-control-dark"} type="text" onChange={(event) => setSecret(event.currentTarget.value)} value={secret} placeholder={"Enter your Code"}/>
                <br />
                <button className={"btn btn-info btn-block"} onClick={() => onClickTwoFAHandler()}>Login</button>
            </div>
        )
    }

}

export default AuthLogin;