import React, {ReactElement, ReactNode, useState} from "react"
import useGetAPI from "../../../http/hooks/useGetAPI";
import PaymentMethod from "../../../models/PaymentMethod";
import APIBuffering from "../../common/APIBuffering";
import {useTranslation} from "react-i18next"
import performPostRequest from "../../../http/functions/performPostRequest";
import {useHistory} from "react-router-dom"

interface PropsI {
    shopId: number
}

const AddPaymentMethodToShopForm: React.FC<PropsI> = (props: PropsI) => {

    const [paymentMethodType, setPaymentMethodType] = useState<String>("")

    const {t} = useTranslation()

    const history = useHistory()

    const getAPI = useGetAPI<PaymentMethod[]>("/payment-methods")
    let paymentMethods: PaymentMethod[] = getAPI.responseBody ? getAPI.responseBody : []


    paymentMethods = paymentMethods.map((object) => {
        return new PaymentMethod(object);
    })

    return (
        <APIBuffering {...getAPI} >
            <form>
                {/* Drop Down */}
                <select className={"form-control select2"} onChange={(event) => {
                    setPaymentMethodType(event.currentTarget.value)
                }}>

                    <option disabled selected hidden>{t("general:form_actions.select_one").toString()}</option>
                    {renderOptions()}
                </select>

                <br />

                {/* Information Display */}
                {paymentMethodType !== "" ? renderInformation() : null}

                <br />

                {/* Create Button */}
                <div style={{ textAlign: "right"}}>
                    <input type={"button"} className={"btn btn-outline-success"} disabled={paymentMethodType === ""} value={t("general:form_actions.create").toString()} onClick={async () => {
                        const {success, data} = await performPostRequest("/shops/" + props.shopId + "/payment-method-credentials", {
                            payment_method_type: paymentMethodType,
                            content: "null"
                        })

                        if(success) {
                            history.push("/payment-method-credentials/" + data.id.toString())
                        }

                    }} />

                    </div>

            </form>
        </APIBuffering>
    )

    function renderOptions(): ReactElement[] {
        const result: ReactElement[] = []

        paymentMethods.forEach((method) => {
            result.push(
                <option value={method.type}>{method.type}</option>
            )
        })

        return result
    }


    function renderInformation(): ReactNode {
        const find = paymentMethods.find((element) => {
            return element.type === paymentMethodType
        })

        const pm = find ? new PaymentMethod(find) : new PaymentMethod({})


        const needsApproval = pm.needs_approval ? <React.Fragment>This Payment Method needs to be approved.<br/></React.Fragment> : null

        return <React.Fragment>
            {needsApproval}
            Fixed Fee per Transaction: {pm.default_fixed_fee} <br />
            Variable Fee: {pm.default_variable_fee}
        </React.Fragment>
    }

}

export default AddPaymentMethodToShopForm