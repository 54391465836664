import React, {ReactElement, ReactNode, useState} from "react"
import ContentEmbedding from "../common/Content/ContentEmbedding";
import Section, {SectionContent, SectionHeader} from "../common/Content/Section";
import useGetAPI from "../../http/hooks/useGetAPI";
import performPostRequest from "../../http/functions/performPostRequest";
import Shop from "../../models/Shop";
import APIBuffering from "../common/APIBuffering";
import { useTranslation } from "react-i18next";

const Integration: React.FC<{}> = () => {

    const {t} = useTranslation("static_page_content")

    const getAPI = useGetAPI<Shop[]>("/shops", {limit: 1000})
    const shops = getAPI.responseBody

    const [selectedShop, setSelectedShop] = useState<number | null>(null)
    const [results, setResults] = useState<object | null>(null)

    return (
        <ContentEmbedding title={"Integration"}>
            <Section>
                <SectionHeader>{t("integration.api_documentation.header")}</SectionHeader>
                <SectionContent>
                    {t("integration.api_documentation.text")}
                </SectionContent>
            </Section>

            <br />

            <Section>
                <SectionHeader>{t("integration.plugins.header")}</SectionHeader>
                <SectionContent>
                    {t("integration.plugins.text")}
                </SectionContent>
            </Section>

            <br />

            <Section>
                <APIBuffering {...getAPI}>
                    <SectionHeader>Pingback Tester</SectionHeader>
                    <SectionContent>

                        {results ? renderResponse() : null}


                        <br />
                        <br />

                        <select className={"form-control select2"} onChange={(event) => {
                            setSelectedShop(parseInt(event.currentTarget.value))
                        }}>
                            <option disabled selected hidden>{t("general:form_actions.select_one").toString()}</option>
                            {renderOptions()}
                        </select>

                        <br />

                        <input type={"button"} className={"btn btn-outline-success form-control"} disabled={selectedShop === null} value={"Test"} onClick={async () => {
                            const {data} = await performPostRequest("/tests/pingback", {shop_id: selectedShop})
                            setResults(data)
                        }} />
                    </SectionContent>
                </APIBuffering>
            </Section>
        </ContentEmbedding>
    )


    function renderOptions(): ReactElement[] {
        const options: ReactElement[] = []

        if(shops)
            shops.forEach((singleShop: Shop) => {
                options.push(<option value={singleShop.id}>{singleShop.title}</option>)
            })
        return options
    }

    // For some reason it causes an error still.
    function renderResponse(): ReactElement[] {
        const display: ReactElement[] = []
        if(results) {
            for(const [key, value] of Object.entries(results))
                    display.push(
                        <div>
                            <h6 style={{color: "white"}}>{key}</h6>
                            <div>{value.toString()}</div><br />
                        </div>
                    )
        }

        return display
    }
}

export default Integration