import User from "../../models/User";
import axios from "../../http/axios/apiv1";

async function retrieveUser(): Promise<User|null> {
        return await axios.get<User>("/users/current").then(({data}) => {
        return data
    }).catch(() => {
        return null
    })

}

export default retrieveUser