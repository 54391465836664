import React, {ReactElement, useState} from "react"
import useGetAPI from "../../../http/hooks/useGetAPI";
import { useTranslation } from "react-i18next";
import performPutRequest from "../../../http/functions/performPutRequest";
import { useHistory } from "react-router-dom";

interface PropsI {
    conflictId: number
}

const SolveConflictForm: React.FC<PropsI> = (props: PropsI) => {

    const [solution, setSolution] = useState<string>("")
    const [note, setNote] = useState<string>("")

    const getAPI = useGetAPI<any>("/conflict-solutions")

    const {t} = useTranslation()

    const history = useHistory()

    return (
        <React.Fragment>
            <select className={"form-control select2"} onChange={(event) => {
                setSolution(event.currentTarget.value)
            }}>
                <option disabled selected hidden>{t("general:form_actions.select_one").toString()}</option>
                {renderOptions()}
            </select>

            <br />

            <input className={"form-control"} type={"text"} placeholder={"Note"} value={note} onChange={(event) => setNote(event.currentTarget.value)}/>

            <div style={{textAlign: "center"}}>
                <input type={"button"} className={"btn btn-outline-success"} disabled={solution === ""} value={t("general:form_actions.solve").toString()} onClick={ async () => {
                    const success = await performPutRequest("/conflicts/" + props.conflictId.toString(), {
                        conflict_solution_name: solution,
                        merchant_note: note,
                    })

                    if(success) {
                        const currentLocation = history.location
                        history.push("/reload")
                        history.replace(currentLocation)
                    }

                }} />
            </div>
        </React.Fragment>
    )

    function renderOptions(): ReactElement[] {
        const result: ReactElement[] = []

        if(getAPI.responseBody)
            getAPI.responseBody.forEach((solution: any) => {
                result.push(<option value={solution.name}> {solution.name} </option>)
            })

        return result
    }
}

export default SolveConflictForm