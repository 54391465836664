import React from "react"
import PaymentMethodPieChart from "../graphs/PaymentMethodPieChart";
import ContentEmbedding from "../common/Content/ContentEmbedding";
import CountryPieChart from "../graphs/CountryPieChart";
import PaymentTransactionLineChart from "../graphs/PaymentTransactionLineChart";
import Section, {SectionContent} from "../common/Content/Section";

const Statistics: React.FC<{}> = () => {

    return (
        <ContentEmbedding title={"Statistics"}>
            <Section>
                <SectionContent>
                    <PaymentTransactionLineChart />
                </SectionContent>
            </Section>

            <br />

            <Section>
                <SectionContent>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <PaymentMethodPieChart/>
                        </div>

                        <div className={"col-md-6"}>
                            <CountryPieChart/>
                        </div>
                    </div>
                </SectionContent>
            </Section>

        </ContentEmbedding>
    )

}

export default Statistics