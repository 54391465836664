import {IDataTableColumn} from "react-data-table-component";
import {TFunction} from "i18next";
import Shop from "./Shop";

class PaymentMethodCredential {
    public id?: number
    public payment_method_type?: string
    public shop_id?: number
    public shop_object?: Shop
    public content?: string
    public is_activated?: boolean
    public is_accountable?: boolean
    public customer_pays_fee?: boolean
    public fixed_fee?: number
    public variable_fee?: number
    public created_at?: string
    public updated_at?: string

    constructor(init: Partial<PaymentMethodCredential>) {
        Object.assign(this, init)
    }

    apiDatatableDisplay(t: TFunction): any {

        const translateYesNo = (value: boolean): string | null => {
            return value !== undefined ? t("boolean:YesNo." + Boolean(value).toString()) : null
        }
        const translateCustomerShop = (value: boolean): string | null => {
            return value !== undefined ? t("boolean:CustomerShop." + Boolean(value).toString()) : null
        }

        return {
            id: this.id,
            payment_method_type: this.payment_method_type,
            is_activated: this.is_activated != undefined ? translateYesNo(this.is_activated) : null,
            is_accountable: this.is_accountable != undefined ? translateYesNo(this.is_accountable): null,
            customer_pays_fee: this.customer_pays_fee != undefined ? translateCustomerShop(this.customer_pays_fee) : null,
            fixed_fee: this.fixed_fee,
            variable_fee: this.variable_fee,
            shop_title: this.shop_object?.title,
        }
    }
}

export const standardColumns: Array<IDataTableColumn<any>> = [
    {
        name: "",
        selector: "id",
        sortable: false,
    },{
        name: "",
        selector: "payment_method_type",
        sortable: false,
    },{
        name: "",
        selector: "is_activated",
        sortable: false,
    },{
        name: "",
        selector: "is_accountable",
        sortable: false,
    },{
        name: "",
        selector: "customer_pays_fee",
        sortable: false,
    },{
        name: "",
        selector: "fixed_fee",
        sortable: false,
    },{
        name: "",
        selector: "variable_fee",
        sortable: false,
    }]

export const approvalColumns: Array<IDataTableColumn<any>> = [
    {
        name: "",
        selector: "id",
        sortable: false,
    },{
        name: "",
        selector: "shop_title",
    },{
        name: "",
        selector: "payment_method_type",
        sortable: false,
    },{
        name: "",
        selector: "is_activated",
        sortable: false,
    },{
        name: "",
        selector: "is_accountable",
        sortable: false,
    },{
        name: "",
        selector: "customer_pays_fee",
        sortable: false,
    }]

export const singleResourceDisplay: Array<SingleResourceDisplayStructure> = [
        {
            identifier: "payment_method_type",
        },{
            identifier: "content",
            editable: "textarea",
        },{
            identifier: "is_activated",
            valueTransform: "BooleanYesNo",
        },{
            identifier: "is_accountable",
            valueTransform: "BooleanYesNo",
            editable: {
                optionTrue: "Yes",
                optionFalse: "No",
            },
        },{
            identifier: "customer_pays_fee",
            valueTransform: (value: any) => value ? "Customer" : "Shop",
            editable: {
                optionTrue: "Customer",
                optionFalse: "Shop",
            },
        },{
            identifier: "fixed_fee",
        },{
            identifier: "variable_fee",
        },{
            identifier: "created_at",
            valueTransform: "DateAndTime",
        },{
            identifier: "updated_at",
            valueTransform: "DateAndTime",
        }
    ]

export default PaymentMethodCredential