// This function mutates the input objects in the array. A deep clone is difficult and does not seem worth the effort for the use case
// This is useful for lists as they are defined in the models (standardColumns) which cannot be translated there easily outside of a functional component
// This function can be called to translate them inside a functional component
import {TFunction} from "i18next";

function translateListOfObjects<T, K extends keyof T>(objects: T[], keyAttribute: K, targetAttribute: string, t: TFunction, keyPrefix: string = ""): void {

    objects.forEach((singleObject) =>  {
        // No need to strictly type this and it is not a trivial task
        // @ts-ignore
        singleObject[targetAttribute] = t(keyPrefix + singleObject[keyAttribute])
    })
}

export default translateListOfObjects