const bracketDarkTheme = {
    table: {
        style: {
            backgroundColor: "#1a2432",
            color: "#fff",
            borderColor: "rgba(255, 255, 255, 0.08)",
            fontWeight: "500"
        }
    },
    tableWrapper: {
        style: {
            backgroundColor: "#1a2432",
            color: "#fff",
            borderColor: "rgba(255, 255, 255, 0.08)",
            fontWeight: "500"
        }
    },
    headCells: {
        style: {
            backgroundColor: "#1a2432",
            color: "#fff",
            borderColor: "rgba(255, 255, 255, 0.08)",
            fontWeight: "500"
        }
    },
    headRow: {
        style: {
            backgroundColor: "#1a2432",
            color: "#fff",
            borderColor: "rgba(255, 255, 255, 0.08)",
            fontWeight: "500"
        }
    },
    cells: {
        style: {
            backgroundColor: "#1a2432",
            color: "#fff",
            borderColor: "rgba(255, 255, 255, 0.08)",
            fontWeight: "500"
        }
    }
}

export default bracketDarkTheme