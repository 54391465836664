import axios from "../axios/apiv1";

async function performPostRequest(endpoint: string | null, instance: object): Promise<{ success:boolean, data: any }> {

    // It's possible to not give an endpoint. This should not leave total failure to make it unnecessary to catch a in every application
    if(endpoint) {
        return await axios.post(endpoint, instance).then(({data}) => {
            return {success: true, data}
        }).catch((error) => {
            console.log(error)
            return {success: false, data: {}}
        })
    }

    console.log("No endpoint was given for the put request.")
    return {success: false, data: {}}
}

export default performPostRequest