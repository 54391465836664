import React, {ReactElement, useContext, useState} from "react"

import Ticket, {standardColumns} from "../../../models/Ticket"
import APIDatatable from "../../common/APIDatatable/APIDatatable";
import translateListOfObjects from "../../../utils/translation/translateListOfObjects";
import {useTranslation} from "react-i18next"
import Section, {SectionContent, SectionHeader} from "../../common/Content/Section";
import ContentEmbedding from "../../common/Content/ContentEmbedding";
import Permission from "../../../models/Permission";
import PermissionContext from "../../../contexts/PermissionContext";
import performPostRequest from "../../../http/functions/performPostRequest";
import {useHistory} from "react-router-dom"
import APISelect from "../../common/FormElement/APISelect";

const TicketList = () =>  {
    const { t } = useTranslation("table_header")

    const history = useHistory()

    const permissionMap = useContext<Map<number,Permission> | null>(PermissionContext)

    const [subject, setSubject] = useState<string>("")
    const [shopId, setShopId] = useState<number>(-1)
    const [category, setCategory] = useState<string>("");
    const [firstMessage, setFirstMessage] = useState<string>("")

    translateListOfObjects(standardColumns, "selector", "name", t,"tickets.")

    return (
        <ContentEmbedding>
            <APIDatatable
                columns={standardColumns}
                endpoint={"/tickets"}
                modelConstructor={(init) => new Ticket(init)}
                searchString
            />

            <br />
            <br />

            <Section>
                <SectionHeader>New Ticket</SectionHeader>
                <SectionContent>
                    <input type={"text"} className={"form-control"} placeholder={t("general:form_fields.subject")} value={subject} onChange={(event) => {
                        setSubject(event.currentTarget.value)
                    }} />

                    <br />

                    <APISelect endpoint={"/ticket-categories"} className={"form-control"} valueKey={"name"} displayKey={"name"} onChange={(event) => setCategory(event.currentTarget.value)}/>

                    <br />

                    <APISelect endpoint={"/shops"} className={"form-control"} valueKey={"id"} displayKey={"title"} onChange={(event) => setShopId(parseInt(event.currentTarget.value)) } />

                    <br/>

                    <textarea className={"form-control"} placeholder={t("general:form_fields.your_first_message")} value={firstMessage} onChange={(event) => {
                        setFirstMessage(event.currentTarget.value)
                    }} />

                    <br />
                    <br />

                    <div style={{ textAlign: "right"}}>
                    <input type={"button"} className={"btn btn-outline-primary"} disabled={shopId === -1 || subject === "" || category === "" || firstMessage === ""} value={t("general:form_actions.create").toString()} onClick={async () => {
                        const {success, data} = await performPostRequest("/tickets", {
                            subject,
                            message: firstMessage,
                            shop_id: shopId,
                            category_name: category,
                        })

                        if(success) {
                            setSubject("")
                            setFirstMessage("")
                            setShopId(-1)
                            history.push("/tickets/" + data.id.toString())
                        }

                    }} />

                    </div>
                </SectionContent>
            </Section>
        </ContentEmbedding>
    )

    function renderOptions(): ReactElement[] {
        const result: ReactElement[] = []

        if(permissionMap)
            permissionMap.forEach((permission, shopId) => {
                if(permission.may_interact_with_tickets)
                    result.push(
                        <option value={shopId}>{shopId}</option>
                    )
            })

        return result
    }

}

export default TicketList