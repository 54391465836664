import axios from "../../http/axios/apiv1";
import {LoginResponse} from "../../types/responses";
import User from "../../models/User";

interface ReturnI {
    success: boolean
    twoFANeeded: boolean
}

/*
 * This function takes email and password to log a user in.
 * @param email The user's full email
 * @param password The user's password
 * @return Will return true if the login was successful, otherwise false.
 */
export async function login(email: string, password: string): Promise<ReturnI> {
    let twoFANeeded = false
    const success: boolean = await axios.post<LoginResponse>("/login", {
        email: email,
        password: password
    }).then(({ status, data }) => {
        if(status == 204) {
            twoFANeeded = true
        } else if(status == 201) {
            axios.defaults.headers.common.Authorization = `Bearer ${data.token}`
        localStorage.setItem('login-response', JSON.stringify(data))
        }

        return true;
    }).catch((error) => {
        return false;
    })

    return {success, twoFANeeded};

 }

 export async function enterTwoFA(secret: string) {
    const success: boolean = await axios.post<LoginResponse>("/login/2fa", {
        secret
    }).then(({ status, data }) => {
        axios.defaults.headers.common.Authorization = `Bearer ${data.token}`
        localStorage.setItem('login-response', JSON.stringify(data))
        return true;
    }).catch((error) => {
        return false;
    })

    return success
 }


export function logout(): void {
    if(localStorage.getItem("impersonating"))
        localStorage.removeItem("impersonating")
    else
        localStorage.removeItem('login-response')
    window.location.href = "/"
}

/*
 * This function will attempt to set the Bearer Token in the header if a token is saved in local storage
 *
 * @return An object {user, token} containing user and token if a token and user could be loaded and set. null otherwise.
 */
export function attemptLoadingSavedAuth(): {user: User, token: string} | null {
    const savedData = localStorage.getItem('login-response')
    if(savedData) {
        const loginResponse = JSON.parse(savedData)

        if (loginResponse.user && loginResponse.token) {
            const token: string = loginResponse.token
            const user = new User(loginResponse.user)
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            return {user, token};
        }

    }

    return null;
}


export default {login, logout, attemptLoadingSavedAuth}