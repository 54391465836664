import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';


export const availableLanguages = ['en', 'de', 'ko'];

i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,

    lng: window.navigator.language.substr(0,2),
    ns: ["navigation", "table_header", "single_resource_pages", "shop_state", "boolean", "general", "languages", "conflict_type_and_solution", "static_page_content", "model_attributes"],
    fallbackLng: 'en',
    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;