import Permission from "../../models/Permission";

function getPermissionFromMap(permissionMap: Map<number, Permission> | null, shopId: number, permissionName: string): null | undefined | boolean {
    if(!permissionMap)
        return null

    const permission: Permission | undefined | null = permissionMap ? permissionMap.get(shopId) : null
    if(!permission)
        return null

    // @ts-ignore
    return permission[permissionName]
}

export default getPermissionFromMap