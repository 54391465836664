import {IDataTableColumn} from "react-data-table-component";
import React from "react";
import {TFunction} from "i18next";

class Shop {

    public id: number = -1
    public title: string = ""
    public description: string = ""
    public state: string = ""
    public in_testing_mode: boolean = true
    public api_token?: string
    public merchant_notification_email?: string
    public success_url?: string
    public cancel_url?: string
    public notify_url?: string
    public website?: string
    //base64 encoded
    public logo?: string;
    public created_at?: string
    public updated_at?: string
    public deleted_at?: string



    constructor(init: Partial<Shop>) {
        Object.assign(this, init)
    }

    genericDisplay() {
        return (
            <>
                <h6 className={"tx-white"}>Ticket Message</h6>
                {this.title}
            </>
        )
    }

    apiDatatableDisplay(t: TFunction): any {
        return {
            id: this.id,
            title: this.title,
            description: this.description,
            state: this.state ? t('shop_state:' + this.state) : null,
            in_testing_mode: t("boolean:YesNo." + Boolean(this.in_testing_mode).toString()),
        }
    }

}

export const standardColumns: Array<IDataTableColumn<any>> = [
        {
            name: "ID",
            selector: "id",
            sortable: true,
        }, {
            name: "Title",
            selector: "title",
            sortable: true,
        }, {
            name: "Description",
            selector: "description",
            sortable: false,
        }, {
            name: "Status",
            selector: "state",
            sortable: false
        }, {
            name: "Testing Mode",
            selector: "in_testing_mode",
            sortable: true,
        }
    ]


export const singleResourceDisplay: Array<SingleResourceDisplayStructure> = [
    {
        identifier: "state",
    },{
        identifier: "in_testing_mode",
        valueTransform: "BooleanYesNo",
        editable: {
            optionTrue: "Yes",
            optionFalse: "No",
        }
    },{
        identifier: "api_token",
    },{
        identifier: "merchant_notification_email",
        editable: "text",
    },{
        identifier: "website",
        editable: "text",
        valueTransform: "ClickableLinkNewTab",
        onEmpty: "None",
    },{
        identifier: "success_url",
        editable: "text",
        onEmpty: "None",
    },{
        identifier: "cancel_url",
        editable: "text",
        onEmpty: "None",
    },{
        identifier: "notify_url",
        editable: "text",
        onEmpty: "None",
    }, {
        identifier: "payout_period_option",
        editable: {
            endpoint: "/payout-periods",
            valueKey: "option",
            displayKey: "option",
        }
    }, {
        identifier: "created_at",
        valueTransform: "DateAndTime",
    }, {
        identifier: "updated_at",
        valueTransform: "DateAndTime",
    }

]

export default Shop;