import React, {useContext} from "react"
import DisplayInformationList from "../../common/Content/DisplayInformationList";
import {useParams} from "react-router-dom"
import useGetAPI from "../../../http/hooks/useGetAPI";
import ContentEmbedding from "../../common/Content/ContentEmbedding";
import PaymentMethodCredential, {singleResourceDisplay} from "../../../models/PaymentMethodCredential";
import Permission from "../../../models/Permission";
import PermissionContext from "../../../contexts/PermissionContext";

const PaymentMethodCredentialSingle: React.FC<{}> = () => {

    const { id } = useParams()

    const GetAPI = useGetAPI<PaymentMethodCredential>("/payment-method-credentials/" + id)
    let paymentMethodCredential = GetAPI.responseBody ? GetAPI.responseBody : new PaymentMethodCredential({})

    const permissionMap: Map<number, Permission> | null = useContext(PermissionContext)
    const permission: Permission | undefined | null = (permissionMap && paymentMethodCredential.shop_id) ? permissionMap.get(paymentMethodCredential.shop_id) : null



    if(!paymentMethodCredential)
       paymentMethodCredential = new PaymentMethodCredential({})


    return (
        <ContentEmbedding title={"Payment Method Credential Overview"} {...GetAPI} >
            <DisplayInformationList
                structure={singleResourceDisplay}
                instance={paymentMethodCredential}
                translationPath={"payment_method_credential"}
                editable={permission ? permission.may_change_shop_settings : false}
                putEndPoint={"/payment-method-credentials/" + id}
                deletable={permission ? permission.may_change_shop_settings : false}
                deleteEndpoint={"/payment-method-credentials/" + id}
            />
        </ContentEmbedding>
        )
}

export default PaymentMethodCredentialSingle